import { Rank, Suit } from "../../pkr-table/shared/card";
import { Round } from "../../pkr-table/shared/hand";
import { ActionBase, Show, Muck, Check } from '../../pkr-table/shared/action';

export class Line {
 
    static createEmpty() :Line {
        return new Line([], [], [], [], [], [], [])
    }

    constructor(public wholeCards : Array< [[Rank, Suit], [Rank, Suit]]>,
                public preflop : Array<ActionBase>,  
                public flop : Array<ActionBase>,
                public turn : Array<ActionBase>,
                public river : Array<ActionBase>,
                public showdown : Array<Show | Muck>,
                public board : Array<[Rank, Suit]>){}
    riverChecksThrough()  :boolean {
        return this.river.every((action) => action instanceof Check)
    }

   public addToCurrentLine(action : ActionBase){
        if((action instanceof Muck || action instanceof Show) && this.round != Round.SHOWDOWN){
            throw new Error("Muck and Show only at showdown")
        }
        switch(this.round){
            case Round.NOT_STARTED: throw new Error("start the game please!")
            case Round.PREFLOP: this.preflop.push(action); break;
            case Round.FLOP: this.flop.push(action); break;
            case Round.TURN: this.turn.push(action); break;
            case Round.RIVER: this.river.push(action); break;
            case Round.SHOWDOWN: 
                if(action instanceof Show || action instanceof Muck){
                    this.showdown.push(action)
                } else {
                    throw new Error("Muck and Show only at showdown")
                }
        }
    }

    public getCurrentLine(){
        switch(this.round){
            case Round.NOT_STARTED: return []
            case Round.PREFLOP: return this.preflop
            case Round.FLOP: return this.flop
            case Round.TURN: return this.turn
            case Round.RIVER: return this.river
            case Round.SHOWDOWN: return this.showdown
        }
    }

    public removeFromCurrentLine(){
        switch(this.round){
            case Round.NOT_STARTED: throw new Error("start the game please!")
            case Round.PREFLOP: this.preflop.pop()
            case Round.FLOP: this.flop.pop()
            case Round.TURN: this.turn.pop()
            case Round.RIVER: this.river.pop()
            case Round.SHOWDOWN: this.showdown.pop()
        }
    }

    round : Round = Round.NOT_STARTED

    // Deprecated
    public calcRoundByInput() : Round {
        switch(this.board.length){
            case 5: 
                if(this.showdown.length > 0){
                    return Round.SHOWDOWN
                } else {
                    return Round.RIVER
                }
            case 4: return Round.TURN
            case 3: return Round.FLOP
            default: 
                
                if(this.wholeCards.length == 0 && this.round == Round.NOT_STARTED){
                    return Round.NOT_STARTED
                } else {
                return Round.PREFLOP
                
                }
        }           
    }


    getWholeCards(playersNum : number): Array<[[Rank, Suit],[Rank, Suit]]> {
        if(this.wholeCards.length > 0){
            return this.wholeCards
          } else {
            let ret = []
            let unknownWholeCards = [[Rank.UNKNOWN, Suit.UNKNOWN],[Rank.UNKNOWN, Suit.UNKNOWN]]
            for(let i = 0; i < playersNum; i++){
                ret.push(unknownWholeCards)
            }
            return ret
                
          }
        
      }
}