export namespace Api {

    export class Player {
        id : number
        name : string
        url : string // this could be the image link OR the content

        constructor(name : string){
            this.name = name;
            this.url = ""
        }
    }

    export class Invitation {
        url : string
        playerId : number
        gameId : number
    }

    export class Game {
        id : number
        name : string
        state : string // TODO ENUM: SCHEDULED, REGISTERING, SEATING, LATEREG, RUNNING, FINISHED
        maxPlayers : number
        maxPlayersPerTable : number
        thinkingTimeInSec : number
    }   

}