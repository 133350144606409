import { Blinds, Pot, UTGStraddle, MississippiStraddle, ButtonAnte, GeneralAnte, BigBlindAnte } from './pot';
import { LayoutConfig } from "./config";
import { DeepCopy } from './clone';
import { Player, PlayerState } from './player';
import { Rank, Suit, WholeCards } from "./card";
import { ActionBase, Call, Check, Bet, Raise, Fold, AllIn } from './action';
import { integerRange } from 'src/app/pkr-texas-holdem/shared/helper';

export enum Round {
  NOT_STARTED = -1,
  PREFLOP = 0,
  FLOP = 1,
  TURN = 2,
  RIVER = 3,
  SHOWDOWN = 4
}

export class RoundState {
  public isBettingFinished: boolean = false;
  public bettingSizes: Array<number> = [];
  public highestBlind: number;
  
  constructor(currentAmount: number, public round: Round = Round.NOT_STARTED) {
    this.highestBlind = currentAmount;
    this.bettingSizes.push(currentAmount);
  }
  get currentAmount(): number {
    return this.bettingSizes[this.bettingSizes.length - 1];
  }
  get previousAmount(): number {
    switch (this.bettingSizes.length) {
      case 1: return 0;
      case 2: return this.highestBlind;
      default: return this.bettingSizes[this.bettingSizes.length - 2];
    }
  }
  isIncomplete(amount: number): boolean {
    return this.minRaise() - amount < 0;
  }
  minRaise(): number {
    return this.currentAmount - this.previousAmount;
  }
  isHigherThanCurrentAmount(amount: number): boolean {
    return amount > this.currentAmount;
  }
  isHigherThanMinRaise(amount: number): boolean {
    let diff = amount - this.currentAmount;
    let minRaise = this.minRaise();
    return minRaise <= diff;
  }
  canCheck(amountInPlay: number): boolean {
    return this.currentAmount <= amountInPlay;
  }
}


export class Hand extends DeepCopy<Hand> {
  static forTexasHoldemBasic(players: Array<Player>, blinds: Blinds, heroIndex: number = -1): Hand {
    let returnable = new Hand([], players.map((p) => p.clone()), new Pot(0), blinds.clone(), heroIndex);
    return returnable
  }
  board: Array<[Rank, Suit]>;
  players: Array<Player>;
  pot: Pot;
  smallestChipDemonitation : number = 1
  layoutConfig: LayoutConfig = new LayoutConfig;
  id: number = 0;
  indexButton: number;
  indexActive: number;
  indexLastBettor: number;
  indexUTG: number = -1;
  roundState: RoundState;
  clone(): Hand {
    let returnable = <Hand>DeepCopy.copy(this);
    returnable.id += 1;
    return returnable;
  }
  get buttonPlayer(): Player {
    return this.players[this.indexButton];
  }
  get smallblindPlayer(): Player {
    if (this.players.length == 2) {
      return this.buttonPlayer;
    }
    else {
      return this.getPlayerAfterBU(1);
    }
  }
  get bigblindPlayer(): Player {
    if (this.players.length == 2) {
      return this.getPlayerAfterBU(1);
    }
    else {
      return this.getPlayerAfterBU(2);
    }
  }
  get utgPlayer(): Player {
    if (this.players.length == 2) {
      return null;
    }
    else {
      return this.getPlayerAfterBU(3);
    }
  }
  get isSomebodyWon() {
    return this.getNumberActive() == 1;
  }
  isAllPlayerAllIn(): boolean {
    return this.players.filter((p) => p.isAllIn).length == this.players.length;
  }
  allPlayerAllinOrNonActive(): boolean {
    let numberActive = this.getNumberActive();
    let numberAllIn = this.players.filter((p) => p.isAllIn).length;
    switch(numberActive - numberAllIn){
      case 0: return true
      case 1: 
        let remainingPlayer = this.players.filter((p) => !p.isAllIn && !(p.isOut()))[0]
        let hasEnoughInPlay = remainingPlayer.amountInPlay == this.roundState.currentAmount
        return hasEnoughInPlay
      default: return false;
    }
  }
  get isFinishingNotPossible(): boolean {
    let everbodyShowedOrMucked = this.players.filter((p) => !p.isOutOrShowed() && !p.isAllIn).length == 0;
    return !everbodyShowedOrMucked && !this.isSomebodyWon;
  }
  get isFinished(): boolean {
    let numberPlayersInFinish = this.players.filter((p) => p.isFinishState() || p.isOut()).length
    let numberPlayers = this.players.length
    return numberPlayersInFinish == numberPlayers
  }
  get isEndShowdown(): boolean {
    let numberShowdownOrOut = this.players.filter((p) => p.isShowdownState() || p.isOut()).length 
    let numberPlayers = this.players.length
    return numberPlayers == numberShowdownOrOut
  }
  get isInShowdownPhase(): boolean {
    return this.roundState.round == Round.SHOWDOWN;
  }

  get isCurrentRoundFinished() : boolean {
    return !this.isSomebodyWon && this.roundState.isBettingFinished;
  }

  getNumberActive(): number {
    return this.getActivePlayers().length;
  }
  getActivePlayers(): Array<Player> {
    return this.players.filter((p) => !p.isOut());
  }
  indexPlayerAfterBU(pos: number): number {
    return (this.indexButton + pos) % this.players.length;
  }
  incIndex(index: number): number {
    return (index + 1) % this.players.length;
  }
  decIndex(index: number): number {
    if (index == 0) {
      return this.players.length - 1;
    }
    else {
      return (index - 1);
    }
  }
  getPlayerAfterBU(pos: number): Player {
    return this.players[this.indexPlayerAfterBU(pos)];
  }
  // THIS Relies so heavy on mutable state ... REWRITE THIS SHIT
  getNextActive(): Player {
    if (this.isSomebodyWon || this.isFinished) {
      throw new Error("game finished already");
    }
    else if (this.roundState.isBettingFinished && this.roundState.round != Round.SHOWDOWN) {
      throw new Error("round finished already");
    }
    else {
      if (this.indexActive == -1) {
        this.initRoundstate();
      }
      else {
        this.indexActive = this.incIndex(this.indexActive);
        let player = this.players[this.indexActive];
        if (player.isOut()) {
          return this.getNextActive();
        }
        else if (player.isAllIn) {
          this.checkBettingFinished();
          if (this.roundState.isBettingFinished || this.isInShowdownPhase) {
            return this.getCurrentActive();
          }
          else {
            return this.getNextActive();
          }
        }
        else {
          this.checkBettingFinished();
        }
        if (this.indexUTG == -1) {
          this.indexUTG = this.indexActive;
        }
      }
      return this.getCurrentActive();
    }
  }
  private checkBettingFinished() {
    let reachedLastBettor = (this.indexActive == this.indexLastBettor);
    let reachedLastActivePlayer = this.indexLastBettor == -1 && this.indexActive == this.indexUTG;
    this.roundState.isBettingFinished = reachedLastBettor || reachedLastActivePlayer;
  }
  private initRoundstate() {
    if(typeof(this.indexButton) === 'undefined' || this.indexButton == -1){
      throw new Error("There is no button player!!!")
    }
    if (this.roundState.round == Round.PREFLOP) {
      if (this.players.length == 2) {
        this.indexActive = this.indexButton;
      }
      else {
        let add = 0;
        if (this.blinds.straddle instanceof UTGStraddle) {
          add += this.blinds.straddle.amounts.length;
        }
        this.indexActive = this.indexPlayerAfterBU(3 + add);
        while (this.getCurrentActive().isAllIn && !this.isAllPlayerAllIn()) {
          this.indexActive = this.incIndex(this.indexActive);
        }
      }
      this.indexUTG = this.indexActive;
    }
    else {
      this.roundState.isBettingFinished = this.allPlayerAllinOrNonActive();
      if (this.roundState.isBettingFinished) {
        console.log("round finishes before it begins: " + this.roundState.round);
      }
    
      var firstToActIndex = this.incIndex(this.indexButton);
      this.indexActive = firstToActIndex
      var noActivePlayerFound = () => (this.incIndex(this.indexActive)== firstToActIndex)
      while ((this.getCurrentActive().isAllIn || this.getCurrentActive().isOut()) && ! noActivePlayerFound()) {
        this.indexActive = this.incIndex(this.indexActive);
      }
      if (noActivePlayerFound()) {
        // all players ar all in with exact same stack number
        var sortedToButton : Array<[Player, number]> = Player.sortToButton(this.players).filter(p => p[0].isAllIn)
        this.indexUTG = sortedToButton[0][1]
        this.indexActive = this.indexUTG;
      } else {
        this.indexUTG = this.indexActive;
      }
      
      
    }
    
  }
  getCurrentActive(): Player {
    if (this.indexActive == -1) {
      throw new Error("no round start");
    }
    else {
      return this.players[this.indexActive];
    }
  }
  getPlayerByName(name : string) : Player {
    let result = this.players.filter((p) => p.name == name)
    if(result.length == 1){
      return result[0]
    } else {
      throw new Error("Player not found")
    }
  }
  constructor(board: Array<[Rank, Suit]>, players: Array<Player>, pot: Pot, public blinds: Blinds, public heroIndex: number = -1) {
    super();
    this.board = board;
    this.players = players;
    this.pot = pot;
    this.roundState = new RoundState(0);
  }
  beginnPreflop(): Hand {
    let returnable = this.clone();
    returnable.resetStates();
    returnable.resetIndexes();
    returnable.roundState = new RoundState(returnable.blinds.getHighestAmount(), Round.PREFLOP);
    returnable.getNextActive().state = PlayerState.ACTIVE;
    return returnable;
  }

  setPlayersCards(wholeCards: Array<Array<[Rank, Suit]>>) : Hand {
    let returnable = this.clone();
    returnable.players.forEach((p: Player, i: number) => {
      p.wholeCards = <WholeCards>wholeCards[i];
    });
    return returnable
  }

  beginnFlop(flop: Array<[Rank, Suit]>): Hand {
    let returnable = this.clone();
    returnable.resetStates();
    returnable.resetIndexes();
    returnable.board = flop;
    returnable.roundState = new RoundState(0, Round.FLOP);
    returnable.getNextActive().state = PlayerState.ACTIVE;
    return returnable;
  }
  beginnTurn(turn: [Rank, Suit]): Hand {
    let returnable = this.clone();
    returnable.resetStates();
    returnable.resetIndexes();
    returnable.board.push(turn);
    returnable.roundState = new RoundState(0, Round.TURN);
    returnable.getNextActive().state = PlayerState.ACTIVE;
    return returnable;
  }
  beginnRiver(river: [Rank, Suit]): Hand {
    let returnable = this.clone();
    returnable.resetStates();
    returnable.resetIndexes();
    returnable.board.push(river);
    returnable.roundState = new RoundState(0, Round.RIVER);
    returnable.getNextActive().state = PlayerState.ACTIVE;
    return returnable;
  }
  beginnShowdown(hasCheckedThrough: Boolean): Hand {
    let returnable = this.clone();
    let lastBettor = returnable.indexLastBettor;
    returnable.resetStates();
    returnable.resetIndexes();
    returnable.roundState = new RoundState(0, Round.SHOWDOWN);
    if (hasCheckedThrough || lastBettor == -1) {
      returnable.getNextActive().state = PlayerState.ACTIVE;
    }
    else {
      returnable.indexActive = lastBettor;
      returnable.indexLastBettor = lastBettor;
      returnable.getCurrentActive().state = PlayerState.ACTIVE;
    }
    return returnable;
  }
  finishHand(): Array<Hand> {
    let returnable : Array<Hand> = []
    let newState = this.clone();
    if (newState.isFinishingNotPossible) {
      throw new Error("hand is not over!");
    }
    else {
      if (newState.isSomebodyWon) {
        // only one left
        let activePlayer = newState.getActivePlayers()[0];
        newState.players.forEach((p) => p.state = PlayerState.LOST);
        activePlayer.state = PlayerState.WON;
        activePlayer.amountInPlay = this.pot.amount
        newState.pot.clear()
        returnable.push(newState)
      }
      else {
        // more than one player competes for the pot (s)
        newState.pot.removeEmptyPots()
        let winnerPics : Array<Hand> = []

        let runningHandState = newState;
        let sidepots = newState.pot.sidepots.reverse().map( (sp) => {
          runningHandState= this.handlePot(runningHandState, sp[1], sp[0])
          return runningHandState
        })
        returnable = returnable.concat(sidepots)
        newState = this.handlePot(runningHandState, newState.getActivePlayers(), this.pot.amount)

      }
      returnable.push(newState)
    }

    if (!newState.isFinished) {
      throw new Error("there was an error during finishing");
    }

    // endstate can be set like so, but needs deeper changes
    // regarding cards
    /* let result = newState.clone()
    result.players.forEach((p) => {
      p.stack.size += p.amountInPlay
      p.amountInPlay = 0
      p.amountInPlayTotal = 0
      if(p.isSitout){
        p.state = PlayerState.SIT_OUT
      } else {
        p.state = PlayerState.INVOLVED
      }
    })
    returnable.push(result)*/

    return returnable;
  }

  handlePot(runningHandState : Hand, involvedPlayers : Array<Player>, potValue : number) : Hand {
    {
      runningHandState = runningHandState.clone()
      let activePlayers = involvedPlayers.filter((p) => ! p.isOut())
      let result : Array<number> = activePlayers.map((p) => p.evaluateHand(this.board))
      let min = Math.min.apply(Math, result);
      let numberPlayersSharingStrongestHand = result.filter((n) => n == min).length
      let amountsForEverybody : Array<number> = this.calcPotSizes(potValue, numberPlayersSharingStrongestHand, this.smallestChipDemonitation)
      let i = 0
      let potPlayerNames = activePlayers.map((pl) => pl.name)
      runningHandState.getActivePlayers().filter((p) => p.evaluateHand(this.board) == min && potPlayerNames.filter((pl) => pl == p.name).length == 1 )
                                   .forEach((p) => {
                                     p.state = PlayerState.WON
                                     p.amountInPlay += amountsForEverybody[i]
                                     i++
                                   })
      runningHandState.getActivePlayers().filter((p) => p.evaluateHand(this.board) > min && potPlayerNames.filter((pl) => pl == p.name).length == 1)
                                  .forEach((p) => {
                                    p.state = PlayerState.LOST
                                  })
      runningHandState.pot.clear()
      return runningHandState

    }
  }

  calcPotSizes(potValue: number, numberPlayersSharingStrongestHand: number, smallestChipDemonitation: number): number[] {
    var times = Math.floor(potValue / numberPlayersSharingStrongestHand);
    var remainder = potValue % numberPlayersSharingStrongestHand
    var returnable = integerRange(1, numberPlayersSharingStrongestHand)
                     .map((n) => {
                          if(remainder > 0){
                            // THIS SHOULD NEVER YIELD A COMMA VALUE ... so don't use 3, 6, 9 etc. 
                            // ass smallest chip denomination
                            let add = remainder / smallestChipDemonitation 
                            remainder -= add
                            return times + add
                          } else {
                            return times
                          }
                          
                      })
    return returnable

  }

  validateAndInit(): void {
    let buttonPlayers = this.players.filter((p: Player) => p.isButton);
    this.verifyOnlyOneButton(buttonPlayers);
    this.indexButton = this.players.indexOf(buttonPlayers[0]);
    this.verifyStraddle();
  }
  private verifyStraddle() {
    if (this.blinds.straddle instanceof UTGStraddle) {
      if (this.players.length - 2 < this.blinds.straddle.amounts.length) {
        throw new Error("to much straddles");
      }
      else if (this.players.length == 2 && this.blinds.straddle instanceof MississippiStraddle) {
        throw new Error("no mississippi Straddle in heads up");
      }
    }
  }
  private verifyOnlyOneButton(buttonPlayers: Player[]) {
    if (buttonPlayers.length > 1) {
      throw new Error("more than one button");
    }
    if(buttonPlayers.length == 0){
      throw new Error("there is no button player!")
    }
  }
  // https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
  private onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  finishRound(): Hand {
    let returnable = this.clone();
    let allInPlayersAsc = returnable.players.filter((p: Player) => p.isAllIn && p.amountInPlay > 0)
      .filter((value, index, self) => returnable.onlyUnique(value.amountInPlay, index, self.map((p) => p.amountInPlay)))
      .sort((a, b) => a.amountInPlay - b.amountInPlay);
    // Sidepot calculation
    allInPlayersAsc.forEach((p: Player) => {
      let amountAllIn = p.amountInPlay;
      let addToPot = 0;
      let playersPlayingFor = [];
      returnable.players.forEach((p: Player) => {
        if (p.amountInPlay > amountAllIn) {
          p.amountInPlay -= amountAllIn;
          addToPot += amountAllIn;
          playersPlayingFor.push(p);
        }
        else {
          addToPot += p.amountInPlay;
          p.amountInPlay = 0;
          if (p.stack.size > 0) {
            playersPlayingFor.push(p);
          }
        }
      });
      returnable.pot.addToCurrentPot(addToPot);
      returnable.pot.addToNewtPot(0, playersPlayingFor);
    });
    let totalInPot = returnable.players.reduce((acc, cur) => acc + cur.amountInPlay, 0);
    returnable.pot.addToCurrentPot(totalInPot);
    returnable.players.forEach((p: Player) => {
      p.amountInPlay = 0;
    });
    this.resetStates(returnable);
    return returnable;
  }
  call(): Hand {
    if(this.isSomebodyWon || this.isFinished || this.roundState.isBettingFinished){
      throw new Error("can't call here...")
    }
    let returnable = this.clone();
    let thereIsNothingToCall: boolean = this.roundState.currentAmount == 0;
    if (thereIsNothingToCall) {
      throw new Error("there is nothing to call");
    }
    returnable.getCurrentActive().performCall(this.roundState.currentAmount);
    return returnable;
  }
  allIn(): Hand {
    if(this.isSomebodyWon || this.isFinished || this.roundState.isBettingFinished){
      throw new Error("can't all-in here...")
    }
    let returnable = this.clone();
    let player = returnable.getCurrentActive();
    let amount = player.stack.size + player.amountInPlay;
    let isHigherThanCall: boolean = returnable.roundState.isHigherThanCurrentAmount(amount);
    if (isHigherThanCall) {
      if (returnable.roundState.isIncomplete(amount)) {
        // TODO incomplete...
      }
      player.performRaise(amount);
    }
    else {
      player.performCall(amount);
    }
    returnable.indexLastBettor = returnable.indexActive;
    returnable.roundState.bettingSizes.push(amount);
    return returnable;
  }
  raise(amount: number): Hand {
    if(this.isSomebodyWon || this.isFinished || this.roundState.isBettingFinished){
      throw new Error("can't raise here...")
    }
    if (this.roundState.currentAmount == 0) {
      throw new Error("you can only raise a bet");
    }
    let returnable = this.clone();
    let player = returnable.getCurrentActive();
    if (amount >= player.stack.size) {
      return this.allIn();
    }
    else {
      returnable.getCurrentActive().performRaise(amount);
      let isRaiseForDifference: boolean = returnable.roundState.isHigherThanMinRaise(amount);
      if (!isRaiseForDifference) {
        throw new Error("raise was to small: " + amount);
      }
      returnable.indexLastBettor = returnable.indexActive;
      returnable.roundState.bettingSizes.push(amount);
      return returnable;
    }
  }
  bet(amount: number): Hand {
    if(this.isSomebodyWon || this.isFinished || this.roundState.isBettingFinished){
      throw new Error("can't bet here...")
    }
    if(this.roundState.currentAmount > 0){
      throw new Error("You can't bet as there is already a wager, you need to raise, call or fold!")
    }
    let returnable = this.clone();
    let player = returnable.getCurrentActive();
    if (amount >= player.stack.size) {
      return this.allIn();
    }
    else {
      if (amount < this.blinds.getHighestAmount()) {
        throw new Error("minbet not fullfilled");
      }
      player.performBet(amount);
      returnable.indexLastBettor = returnable.indexActive;
      returnable.roundState.bettingSizes.push(amount);
      return returnable;
    }
  }
  fold(): Hand {
    if(this.isSomebodyWon || this.isFinished || this.roundState.isBettingFinished){
      throw new Error("can't fold here...")
    }
    let returnable = this.clone();
    returnable.getCurrentActive().performFold();
    // reset UTG index
    if (returnable.indexActive == returnable.indexUTG) {
      returnable.indexUTG = -1;
    }
    // walk
    returnable.roundState.isBettingFinished = returnable.allPlayerAllinOrNonActive()
    return returnable;
  }
  check(): Hand {
    if(this.isSomebodyWon || this.isFinished || this.roundState.isBettingFinished){
      throw new Error("can't check here...")
    }
    let returnable = this.clone();
    let player = returnable.getCurrentActive();
    if (!this.roundState.canCheck(player.amountInPlay)) {
      throw new Error("Can't check when not enough in play!");
    }
    player.performCheck();
    return returnable;
  }
  show(cards: [[Rank, Suit], [Rank, Suit]]): Hand {
    console.log(this.roundState)
    if (this.roundState.round != Round.SHOWDOWN) {
      throw new Error("Show only at showdown");
    }
    let returnable = this.clone();
    let player = returnable.getCurrentActive();
    player.state = PlayerState.SHOWED;
    player.wholeCards = cards;
    return returnable;
  }

  // is a better fold ... so 
  muck(): Hand {
    if (this.roundState.round != Round.SHOWDOWN) {
      throw new Error("Muck only at showdown");
    }

    if(this.isSomebodyWon || this.isFinished || this.roundState.isBettingFinished){
      throw new Error("can't muck here...")
    }
    let returnable = this.clone();
    let player = returnable.getCurrentActive();
    player.state = PlayerState.MUCKED;
    // reset UTG index
    if (returnable.indexActive == returnable.indexUTG) {
      returnable.indexUTG = -1;
    }
    // walk possible....
    returnable.roundState.isBettingFinished = returnable.allPlayerAllinOrNonActive()
    return returnable;
  }

  get allowedActions() : Array<ActionBase> {
    let returnable = []
    if(! (this.isFinished || this.isSomebodyWon || this.roundState.isBettingFinished)){
      let activePlayer = this.getCurrentActive()
      if(activePlayer){
        if(this.roundState.canCheck(activePlayer.amountInPlay)){
          returnable.push(new Check())
        } else {
          returnable.push(new Call())
          returnable.push(new Fold())
        }
        if(this.roundState.currentAmount == 0){
          const minBet = this.blinds.getHighestAmount();
          if(activePlayer.getTotal() - minBet <= 0){
            returnable.push(new Bet(activePlayer.getTotal()))
          } else {
            returnable.push(new Bet(minBet))
            
          }
        } else {
          const minRaise = this.roundState.minRaise();
          if(activePlayer.getTotal() - minRaise <= 0){
            returnable.push(new Raise(activePlayer.getTotal()))
          } else {
            returnable.push(new Raise(minRaise))
          }
        }
      }
      returnable.push(new AllIn())
    }
    return returnable
  }

  getWholeCards() : WholeCards {
    if(this.getCurrentActive()){
      return this.getCurrentActive().wholeCards
    } else {
      return [[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]]
    }
  }

  getTotal() : number {
    if(this.getCurrentActive()){
      return this.getCurrentActive().getTotal()
    } else {
      return 0
    }
  }

  get minAmount() : number {
    if(this.roundState.currentAmount == 0){
      return this.blinds.getHighestAmount()
    } else {
      return this.roundState.minRaise() + this.roundState.currentAmount
    }
  }

  get isBet() : boolean {
    return this.allowedActions.filter((a) => a instanceof Bet).length == 1
  }

  get canCheck() : boolean {
    return this.allowedActions.filter((a) => a instanceof Check).length == 1
  }

  get canCall() : boolean {
    return this.allowedActions.filter((a) => a instanceof Call).length == 1
  }

  get canFold() : boolean {
    return this.allowedActions.filter((a) => a instanceof Fold).length == 1
  }

  nextPlayer(): Hand {
    let returnable = this.clone();
    returnable.resetStates();
    let player = returnable.getNextActive();
    if (!returnable.roundState.isBettingFinished) {
      player.state = PlayerState.ACTIVE;
    }
    return returnable;
  }
  resetStates(h: Hand = this): void {
    h.players.forEach((p: Player) => {
      if (p.state == PlayerState.FOLDS) {
        p.state = PlayerState.FOLDED;
      }
      if (p.state == PlayerState.ALL_IN) {
        p.state = PlayerState.IS_ALL_IN;
      }
      if (!p.isOutOrShowed()) {
        if(p.isSitout){
          // can happen after blinds or ante
          p.state = PlayerState.SIT_OUT
        } else {
          p.state = PlayerState.INVOLVED;
        }
      }
    });
  }
  resetIndexes() {
    this.indexActive = -1;
    this.indexLastBettor = -1;
  }
  letPlayersPostAntes(): Hand {
    let returnable = this.clone();
    returnable.handleAnte(returnable);
    return returnable;
  }
  handleAnte(hand: Hand): void {
    if (hand.blinds.ante) {
      switch (hand.blinds.ante.constructor.name) {
        case ButtonAnte.name:
          hand.blinds.ante.applyAnte(hand.buttonPlayer);
          break;
        case GeneralAnte.name:
          hand.players.forEach((p: Player) => {
            hand.blinds.ante.applyAnte(p);
          });
          break;
        case BigBlindAnte.name:
          hand.blinds.ante.applyAnte(hand.bigblindPlayer);
          break;
        default:
          return;
      }
    }
  }
  letPlayersPostBlinds(): Hand {
    let returnable = this.clone();
    returnable.handleBlinds(returnable);
    return returnable;
  }
  handleBlinds(hand: Hand): void {
    hand.smallblindPlayer.putInPlayUntil(hand.blinds.smallBlind);
    hand.smallblindPlayer.state = PlayerState.PLACED_SB;
    hand.bigblindPlayer.putInPlayUntil(hand.blinds.bigBlind);
    hand.bigblindPlayer.state = PlayerState.PLACED_BB;
    // TODO handle mississippi straddle
    if (hand.blinds.straddle instanceof UTGStraddle) {
      hand.blinds.straddle.amounts.map((e, i) => [e, i + 3])
        .forEach((amountPos: [number, number]) => {
          hand.getPlayerAfterBU(amountPos[1]).putInPlayUntil(amountPos[0]);
          hand.getPlayerAfterBU(amountPos[1]).state = PlayerState.PLACED_STRADDLE;
        });
    }
  }
}
