import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { UnitNumber } from '../shared/player';
import { Currency } from '../shared/pot';
import { StackService } from '../stack/stack.service';

@Component({
  selector: '[pot]',
  templateUrl: './pot.component.html',
  styleUrls: ['./pot.component.css']
})
export class PotComponent implements OnInit {

//TODO ADD Total pot size

  @Input()
  potSize : UnitNumber = new UnitNumber(0, Currency.PLAY);

  @Input()
  bars : number = 7; 

  @Input()
  maxChips : number;

  @Input()
  isHorizontal : boolean = true;

  @Input()
  showPotDesc : boolean

  @Input() bbSize : number;

  @Input()
  displayInBB: Boolean;

  unit : Currency; 

  relativeSpaceBetweenPotAndRest : number = 3

  constructor(private stackService: StackService) { }

  get calcPotSize() : number {
    if (this.displayInBB) {
      this.unit = Currency.BB
      return this.potSize.size / this.bbSize
    } else {
      this.unit = this.potSize.unit;
    
      return this.potSize.size
    }
  }

  ngOnInit() {
  
  }

  get coordForPotDesc() : [number, number] {
    let stackConfig = this.stackService.convertAmountToStackConfig(this.potSize.size, this.bars, this.maxChips, this.potSize.unit)
    let barsUsed = stackConfig.filter((e : Array<any>) => e.length > 0).length;
    if(this.isHorizontal){
      if(barsUsed < 6){
        return [650 + barsUsed * 230, 250];
      } else {

        return [850, 250];// [650 + barsUsed * 230, 250];
      }
    } else {
      return [10, 500 + barsUsed * 150];
    }
  }

}
