import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: string, currency?: string): any {
    switch(currency){
      case '$':
        return currency + value;
      case '€':
        return value + currency;
      case undefined: 
        return value
      default: 
        return value + currency ;
    }
    
  }

}
