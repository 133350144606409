import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';



@Component({
  selector: 'lsop-header',
  templateUrl: './lsop-header.component.html',
  styleUrls: ['./lsop-header.component.css']
})
export class LsopHeaderComponent implements OnInit {

  items : Array<MenuItem>

  constructor() { }

  ngOnInit(): void {
    this.items = [
      {
        label: 'File',
        items: [{
                label: 'New', 
                icon: 'pi pi-fw pi-plus',
                items: [
                    {label: 'Project'},
                    {label: 'Other'},
                ]
            },
            {label: 'Open'},
            {label: 'Quit'}
        ]
      },
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        items: [
            {label: 'Delete', icon: 'pi pi-fw pi-trash'},
            {label: 'Refresh', icon: 'pi pi-fw pi-refresh'}
        ]
      }
   ];
  }


}
