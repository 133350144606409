import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Rank, Suit } from "../shared/card";
@Component({
  selector: '[cards-row]',
  templateUrl: './cards-row.component.html',
  styleUrls: ['./cards-row.component.css']
})
export class CardsRowComponent implements OnInit {
  
  @Input()
  cardList : Array<[Rank, Suit]>
  @Input() cardSize : number = 4;

  @Output() cardsActivated = new EventEmitter<Array<[Rank, Suit]>>();

  @Input()
  cardsActivatedBeginning : Array<[Rank, Suit]> = []

  @Input()
  cardsNonActive : Array<[Rank, Suit]> = []

  cardsActivatedInRow : Array<[Rank, Suit]> = []

  shouldMakeNotActive(card: [Rank, Suit]) : boolean {
    let cardNotActivated = this.cardsNonActive.filter((c) => (c[0] == card[0] && c[1] == card[1])).length > 0
    return cardNotActivated
  }

  shouldMakeClickable(card : [Rank, Suit]) : boolean {
    const cardActivated = this.cardsActivatedInRow.filter((c) => (c[0] == card[0] && c[1] == card[1])).length > 0 ||
                          this.cardsActivatedBeginning.filter((c) => (c[0] == card[0] && c[1] == card[1])).length > 0;
    return cardActivated 
  }

  constructor() { }

  ngOnInit() {
    if(this.cardsActivatedBeginning.length > 0){
      this.cardsActivatedInRow = this.cardsActivatedBeginning
      this.cardsActivated.emit(this.cardsActivatedBeginning)
    }
  }

  getDistanceBetweenCardsInPx(index : number) : number {
    return index * (this.cardSize * 15.5);
  }
  
  onCardClicked(card : [Rank, Suit]) : void {
    let newList = this.cardsActivatedInRow.filter((c) =>  ! (c[0] == card[0] && c[1] == card[1]))
    const hasAddedCard = newList.length == this.cardsActivatedInRow.length;
    if(hasAddedCard){
      this.cardsActivatedInRow.push(card)
    } else {
      this.cardsActivatedInRow = newList
    }
    this.cardsActivated.emit(this.cardsActivatedInRow)
  }
}
