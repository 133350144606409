<div id="overlay">
    <p-card class="card">
        <h1>{{overlayText}}</h1>
        <!-- <p-tabView>
            <p-tabPanel header="Header 1" >
                
            </p-tabPanel>
            <p-tabPanel header="Header 2">
                Content 2
            </p-tabPanel>
            <p-tabPanel header="Header 3">
                Content 3
            </p-tabPanel>
        </p-tabView> -->
    </p-card>
</div>