import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PkrTableModule } from './pkr-table/pkr-table.module';
import { PkrTexasHoldemComponent } from './pkr-texas-holdem/pkr-texas-holdem.component';
import { ReplayControlComponent } from './replay-control/replay-control.component';
import { ActionControlComponent } from './action-control/action-control.component';
import { CardPickerComponent } from './card-picker/card-picker.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';


// Prime ng
import { FormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// use prime ng
import {SidebarModule} from 'primeng/sidebar';
import {ButtonModule} from 'primeng/button';
import { BetSliderComponent } from './action-control/bet-slider/bet-slider.component';
import {SliderModule} from 'primeng/slider';
import {TabViewModule} from 'primeng/tabview';
import {CardModule} from 'primeng/card';

// own comps

import { HoldemReviewerComponent } from './holdem-reviewer/holdem-reviewer.component';
import { LayoutModule } from './layout-module/layout.module';
import { HoldemDemoComponent } from './holdem-demo/holdem-demo.component';
import { RegisterComponent } from './register/register.component';
import { OverlayComponent } from './holdem-demo/overlay/overlay.component';
import { PlayerSettingsComponent } from './register/player-settings/player-settings.component';


const appRoutes: Routes = [
  { path: 'review', component: HoldemReviewerComponent} ,
  { path: 'register', component: RegisterComponent} ,
  { path: '', component: HoldemDemoComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    PkrTexasHoldemComponent,
    ReplayControlComponent,
    ActionControlComponent,
    CardPickerComponent,
    BetSliderComponent,
    HoldemReviewerComponent,
    HoldemDemoComponent,
    RegisterComponent,
    OverlayComponent,
    PlayerSettingsComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    PkrTableModule,
    SidebarModule,
    ButtonModule,
    SliderModule,
    CardModule,
    TabViewModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    LayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
