<!--
SVG Circles filled with picture:

https://stackoverflow.com/questions/29442833/svg-image-inside-circle
-->
<svg cards-row *ngIf="! player.isOut() " [cardList]='player.wholeCards' [cardSize]='cardsSize' attr.x='{{cardsX}}%' attr.y='{{cardsY}}%'  />
<svg:defs>
  <pattern attr.id="{{getPlayerPicId()}}" x="0%" y="0%" height="100%" width="100%" viewBox="0 0 512 512">
    <svg:image x="0%" y="0%"  attr.xlink:href="{{player.image}}"></svg:image>
  </pattern>
</svg:defs>
<svg:rect y="10%" rx="15" width="70%" height="66%" attr.class="{{player.state}}" stroke="black" stroke-width="1.5%" />
<svg:circle id="sd" cx="35%" cy="10%" r="19%" attr.class="{{player.state}} medium" />
<svg:circle id="sd" class="medium" cx="35%" cy="10%" r="19%" attr.fill="url(#{{getPlayerPicId()}})" stroke="black" stroke-width="1.5%" />

<svg:text *ngIf='!doesAction()' text-anchor="middle" x="35%" y="44%" class="font-playername basic-font ">{{player.name}}</svg:text>
<svg:line *ngIf='!doesAction()' x1="10%" y1="50%" x2="60%" y2="50%" stroke="black" stroke-width="1.5%" />
<svg:text *ngIf='!doesAction()' (click)="toggleBBs()" text-anchor="middle" x="35%" y="68%" class="basic-font font-stack stacksize">{{playerStackSize}}{{unit}}</svg:text>

<!--<svg:text style="fill: red" text-anchor="middle" x="35%" y="50%" class="basic-font font-stack ">{{oH}}/{{oV}}</svg:text>-->

<svg:text *ngIf='doesAction()' text-anchor="middle" x="35%" y="55%" class="basic-font font-action">{{player.state | titlecase}}</svg:text>
<svg button *ngIf="player.isButton" attr.x="{{buttonPoint[0]}}%" attr.y="{{buttonPoint[1]}}%"/>
<svg all-in-triangle *ngIf="player.isAllIn" [base_length] ='triangleSize' attr.x="{{trinanglePoint[0]}}%" attr.y="{{trinanglePoint[1]}}% "  />
<svg wager *ngIf="player.amountInPlay > 0"  [showWagerDesc]="showWagerDesc" [amount]='player.getAmountInPlayWithUnit()' height="4%" width="4%" [bars]='1' [chips]='10' attr.x="{{wagerPoint[0]}}%" attr.y="{{wagerPoint[1]}}%" />
