// https://gist.github.com/dev-thalizao/affaac253be5b5305e0faec3b650ba27
export function zip<S1, S2>(firstCollection: Array<S1>, lastCollection: Array<S2>): Array<[S1, S2]> {
    const length = Math.min(firstCollection.length, lastCollection.length);
    const zipped: Array<[S1, S2]> = [];

    for (let index = 0; index < length; index++) {
        zipped.push([firstCollection[index], lastCollection[index]]);
    }

    return zipped;
}

export function* rangeGen(from : number = 0 ,to : number) {   
    for(let i = from; i <= to; i++) yield i;   
};  

export function range(from : number = 0 ,to : number) : Array<number> {
    return [...rangeGen(from,to)]
}