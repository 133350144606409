import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Review } from '../pkr-texas-holdem/shared/model';

@Component({
  selector: 'app-holdem-reviewer',
  templateUrl: './holdem-reviewer.component.html',
  styleUrls: ['./holdem-reviewer.component.css']
})
export class HoldemReviewerComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  state : Review

  ngOnInit() {
    let history = this.route.snapshot.queryParamMap.get("history")
    this.state = Review.FromStringRepresentation(history)  
    // TODO  Try catch and show error!
  }

}
