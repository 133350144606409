import { Rank, Suit, Card, WholeCards, cardFromString } from "./card";
import { Player, UnitNumber, PlayerState } from './player';
import { Currency, Blinds, Ante, Straddle, UTGStraddle, NoAnte, GeneralAnte, BigBlindAnte, ButtonAnte } from './pot';

const CARD_REGEX = /([2-9|A|K|Q|J|T][s|h|d|c])/g
const PAIR_REGEX = /\[([2-9|A|K|Q|J|T][s|h|d|c])([2-9|A|K|Q|J|T][s|h|d|c])\]/

export interface FromString<T> {
    fromString(input : string) : T;
}


export abstract class GameAction {
    public static fromGameAction(ga : string) : GameAction {
        let command = ga.split(" ")
        console.log(command)
        switch(command[0]){
            case "blinds":  return new SetBlinds().fromString(ga)
            case "currency": return new SetCurrency().fromString(ga)
            case "players": return new SetPlayers().fromString(ga)
            case "stack": return new SetStack().fromString(ga)
            case "button": return new SetButton().fromString(ga)
            case "start": return new StartHand().fromString(ga)
            case "cards": return new DealCards().fromString(ga)
            case "flop": return new DealFlop().fromString(ga)
            case "turn": return new DealTurn().fromString(ga)
            case "river": return new DealRiver().fromString(ga)
            case "showdown": return new StartShowdown().fromString(ga)
            default: throw new Error("game action not known: " + command[0])
        }
    }

    protected representation : String = ""

    setRepresentation(s : String){
        this.representation = s
    }
}

//blinds (ante ALL <amount>)? sb <amount> bb <amount> straddle <amount1> <amount2> ... <amountn>
export class SetBlinds extends GameAction implements FromString<SetBlinds>{
    constructor(public b? : Blinds ) {
        super()
    }

    fromString(input: string): SetBlinds {
        if(!input.startsWith("blinds")){
            throw new Error("Not a blinds game action!")
        }
        let blindRegex = input.match(/blinds (?:(ante) (BB|BU|ALL) (\d+) )?(sb) (\d+) (bb) (\d+)(?: (straddle) ((?:(?:\d+) ?)*))?/)
        if(blindRegex == null ){
            throw new Error("blinds input problem")
        }
        let ante : Ante = this.extractAnte(blindRegex)
        let straddle : Straddle = this.extractStraddle(blindRegex)
        let sb : number = this.extractBlinds(blindRegex, "sb")
        let bb : number = this.extractBlinds(blindRegex, "bb")
        let value = new SetBlinds(new Blinds(sb, bb, straddle, ante))
        value.setRepresentation(input)
        return value
    }

    extractAnte(matcherRes : Array<string>) : Ante {
        let index = matcherRes.indexOf("ante")
        let anteKind : string = matcherRes[index + 1]
        let anteValue : string = matcherRes[index + 2]
        if(index == -1){
            return new NoAnte()
        } else {
            if(typeof(anteKind) === 'undefined' || typeof(anteValue) === 'undefined'){
                throw new Error("ante values empty!")
            }
            switch(anteKind){
                case "ALL": return new GeneralAnte(parseFloat(anteValue))
                case "BB": return new BigBlindAnte(parseFloat(anteValue))
                case "BU": return new ButtonAnte(parseFloat(anteValue))
            }
        }
    }

    extractStraddle(matcherRes : Array<string>) : Straddle {
        let index = matcherRes.indexOf("straddle")
        let parsable : string = matcherRes[index + 1]
        if(index == -1){
            return null
        } else {
            return new UTGStraddle(parsable.split(" ").map((numberAsString) => parseFloat(numberAsString.trim())))
        }   
    }

    extractBlinds(matcherRes : Array<string>, sbOrBB : string) : number {
        let index = matcherRes.indexOf(sbOrBB)
        let parsable : string = matcherRes[index + 1]
        if(index == -1 || typeof(parsable) === 'undefined'){
            throw new Error("there is no " + sbOrBB + "!")
        }
        return parseFloat(parsable)
    }

}

// currency [EUR]
export class SetCurrency extends GameAction implements FromString<SetCurrency>{
    constructor(public c? : Currency ) {
        super()
    }

    fromString(input: string): SetCurrency {
        if(!input.startsWith("currency")){
            throw new Error("Not a currency game action!")
        }
        let m = input.match(/currency \[(\S{3,4})\]/)
        if(m == null ){
            throw new Error("Currency input problem")
        }
        let res = m[1]
        let value = new SetCurrency(Currency[res])
        value.setRepresentation(input)
        return value
    }
}

// players [player1;....]
export class SetPlayers extends GameAction implements FromString<SetPlayers>{
    constructor(public players? : Array<Player> ) {
        super()
    }

    fromString(input: string): SetPlayers {
        if(!input.startsWith("players")){
            throw new Error("Not a set players game action!")
        }
        let m = input.match(/players \[(\S+)]/)
        if(m == null ){
            throw new Error("players input problem")
        }
        let res = m[1].split(";")
    
        let ret : Array<Player> = res.map((playerName : string) => new Player(playerName, new UnitNumber(), PlayerState.INVOLVED, <WholeCards><unknown>[],0,false))
        let value = new SetPlayers(ret)
        value.setRepresentation(input)
        return value
    }
}

// stack [Player:123]
export class SetStack extends GameAction implements FromString<SetStack> {
    constructor(public stacks? : [string, number] ) {
        super()
    }

    fromString(input: string): SetStack {
        if(!input.startsWith("stack")){
            throw new Error("Not a stack game action!")
        }
        let m = input.match(/stack \[(\S+):(\d+)\]/)
        if(m == null || m.length != 3){
            throw new Error("stack input problem")
        }
        let playerName = m[1]
        let size = parseFloat(m[2])
        let value = new SetStack([playerName, size])
        value.setRepresentation(input)
        return value
    }
    
}

// \[([2-9|A|K|Q|J|T][s|h|d|c])(?:(?:,([2-9|A|K|Q|J|T][s|h|d|c])))(?:(?:,([2-9|A|K|Q|J|T][s|h|d|c])))\]

// button [player]
export class SetButton extends GameAction implements FromString<SetButton> {
    constructor(public playerName? : string ) {
        super()
    }

    fromString(input: string): SetButton {
        if(!input.startsWith("button")){
            throw new Error("Not a button game action!")
        }
        let m = input.match(/button \[(\S+)]/)
        if(m == null || m.length != 2){
            throw new Error("button input problem")
        }
        let playerName = m[1]
        let ret = new SetButton(playerName)
        ret.setRepresentation(input)
        return ret
    }
}

// start
export class StartHand extends GameAction implements FromString<StartHand>{

    fromString(input: string): StartHand {
        if(!input.startsWith("start")){
            throw new Error("Not a start game action!")
        }
        
        let ret = new StartHand()
        ret.setRepresentation(input)
        return ret
    }
}

// cards [playername:Ad4d]
export class DealCards extends GameAction implements FromString<DealCards> {
    constructor(public playerName? : string, public wholeCards? : WholeCards ) {
        super()
        
    }

    fromString(input: string): DealCards {
        if(!input.startsWith("cards")){
            throw new Error("Not a cards game action!")
        }
        let m = input.match(/cards \[(\S+):([2-9|A|K|Q|J|T][s|h|d|c])([2-9|A|K|Q|J|T][s|h|d|c])\]/)
        if(m == null || m.length != 4){
            throw new Error("cards input problem")
        }
        let ret = new DealCards(m[1], [cardFromString(m[2]), cardFromString(m[3])])
        ret.setRepresentation(input)
        return ret
    }
}

// flop [2d3d4d]
export class DealFlop extends GameAction implements FromString<DealFlop> {
    constructor(public flop? : [Card, Card, Card]) {
        super()
    }

    fromString(input: string): DealFlop {
        if(!input.startsWith("flop")){
            throw new Error("Not a flop game action!")
        }
        let m = input.match(/flop \[([2-9|A|K|Q|J|T][s|h|d|c])([2-9|A|K|Q|J|T][s|h|d|c])([2-9|A|K|Q|J|T][s|h|d|c])\]/)
        if(m == null || m.length != 4){
            throw new Error("flop input problem")
        }
        let ret = new DealFlop([cardFromString(m[1]), cardFromString(m[2]), cardFromString(m[3])])
        ret.setRepresentation(input)
        return ret 
    }
}

// turn [...]
export class DealTurn extends GameAction implements FromString<DealTurn> {
    constructor(public turn? : Card) {
        super()
    }

    fromString(input: string): DealTurn {
        if(!input.startsWith("turn")){
            throw new Error("Not a turn game action!")
        }
        let m = input.match(/turn \[([2-9|A|K|Q|J|T][s|h|d|c])\]/)
        if(m == null || m.length != 2){
            throw new Error("turn input problem")
        }

        let ret = new DealTurn(cardFromString(m[1]))
        ret.setRepresentation(input)
        return ret 
    }
}

// river [...]
export class DealRiver extends GameAction implements FromString<DealRiver> {
    constructor(public river? : Card) {
        super()
    }

    fromString(input: string): DealRiver {
        if(!input.startsWith("river")){
            throw new Error("Not a river game action!")
        }
        let m = input.match(/river \[([2-9|A|K|Q|J|T][s|h|d|c])\]/)
        if(m == null || m.length != 2){
            throw new Error("river input problem")
        }

        let ret = new DealRiver(cardFromString(m[1]))
        ret.setRepresentation(input)
        return ret 
    }
}

// showdown
export class StartShowdown extends GameAction implements FromString<StartShowdown> {
    fromString(input: string): StartShowdown {
        if(!input.startsWith("showdown")){
            throw new Error("Not a showdown game action!")
        }        
        let ret = new StartShowdown()
        ret.setRepresentation(input)
        return ret
    }
}

// TODO!!!
// eval [...,...]
export class DeclareWinner extends GameAction implements FromString<DeclareWinner> {
    constructor(public wholeCards : Array<WholeCards> ) {
        super()
    }
    fromString(input: string): DeclareWinner {
        throw new Error("Method not implemented.");
    }
}

export enum Action {
    CHECK = "x",
    CALL = "c",
    BET = "b",
    RAISE = "r",
    FOLD = "f",
    ALL_IN = "a",
    MUCK = "m",
    SHOW = "s"
}

export abstract class ActionBase {
    static fromAction(a: string): ActionBase {
        console.log(a)
        switch (a[0]) {
            case "x": return new Check().fromString(a);
            case "c": return new Call().fromString(a);
            case "a": return new AllIn().fromString(a);
            case "f": return new Fold().fromString(a);
            case "m": return new Muck().fromString(a);
            case "s": return new Show().fromString(a);
            case "b": return new Bet().fromString(a)
            case "r": return new Raise().fromString(a)
            default: throw new Error("not regocnized action")
               
        }
    }
    constructor(public a: Action) { }
}
// Game actions
// Player actions
export class Muck extends ActionBase implements FromString<Muck> {
    constructor() {
        super(Action.MUCK);
    }
    
    fromString(input: String): Muck {
        if(input != "m"){
            throw new Error("This is not muck")
        } 
        return new Muck()
    }
}
export class Show extends ActionBase implements FromString<Show> {
    public cards: WholeCards
    constructor(cards? : WholeCards) {
        super(Action.SHOW);
        if(cards){
            this.cards = cards
        } else {
            this.cards = cards
        }
    }

    fromString(input: string): Show {
        let pair = input.split(" ")
        if(pair.length != 2){
            throw new Error("Show input error")
        }
        let regex = pair[1].match(PAIR_REGEX)
        if(regex == null){
            throw new Error("This is not show")
        } 
        return new Show([cardFromString(regex[1]), cardFromString(regex[2])])
    }
}
export class Call extends ActionBase implements FromString<Call> {
    constructor() {
        super(Action.CALL);
    }

    fromString(input: String): Call {
        if(input != this.a.toString()){
            throw new Error("This is not call")
        } 
        return new Call()
    }
}
export class Check extends ActionBase implements FromString<Check> {
    constructor() {
        super(Action.CHECK);
    }

    fromString(input: String): Check {
        if(input != this.a.toString()){
            throw new Error("This is not check")
        } 
        return new Check()
    }
}
export class Fold extends ActionBase implements FromString<Fold> {
    constructor() {
        super(Action.FOLD);
    }

    fromString(input: String): Fold {
        if(input != this.a.toString()){
            throw new Error("This is not fold")
        } 
        return new Fold()
    }
}
export class AllIn extends ActionBase implements FromString<AllIn> {
    constructor() {
        super(Action.ALL_IN);
    }

    fromString(input: String): AllIn {
        if(input != this.a.toString()){
            throw new Error("This is not all-in")
        } 
        return new AllIn()
    }
}
export class Raise extends ActionBase implements FromString<Raise> {
    public amount: number = 0
    constructor(amount? : number) {
        super(Action.RAISE);
        if(typeof(amount) !== 'undefined'){
            this.amount = amount
        }
    }

    fromString(input: String): Raise {
        let s = input.split(" ");
        if(s[0] != this.a.toString()){
            throw new Error("This is not a raise!")
        } 
        return new Raise(parseFloat(s[1]))
    }
}
export class Bet extends ActionBase implements FromString<Bet> {
    public amount: number = 0
    constructor(amount? : number) {
        super(Action.BET);
        if(typeof(amount) !== 'undefined'){
            this.amount = amount
        }
    }

    fromString(input: String): Bet {
        let s = input.split(" ");
        if(s[0] != this.a.toString()){
            throw new Error("This is not a bet!")
        } 
        return new Bet(parseFloat(s[1]))
    }
}
