import { Injectable } from '@angular/core';
import { Hand, Round, RoundState } from '../pkr-table/shared/hand';
import { Line } from './shared/lines';
import { ActionBase, Call, Check, Fold, Raise, Bet, AllIn, Muck, Show, GameAction, DealFlop, DealTurn, DealRiver, StartShowdown, DeclareWinner, DealCards, StartHand } from '../pkr-table/shared/action';
import { Rank, Suit, WholeCards } from '../pkr-table/shared/card';
import { HandHistoryService } from './shared/model';
import { handleShowdownAction, finishHandIfPossible, handleActionForState } from './shared/handle_actions';

@Injectable({
  providedIn: 'root'
})
export class TexasHoldemStateTrackerService implements HandHistoryService {

  constructor() { }

  getHistory(): Hand[] {
    return this.history
  }

  private curHandState : Hand 
  public history : Array<Hand>  = []
  private line : Line

  public init(hand : Hand, history : Array<Hand>, line : Line){
    this.curHandState = hand
    this.history = history
    this.line = line
  }

  public handlePreflop() : Hand {
    this.line.round = Round.PREFLOP
    let begin = this.curHandState.beginnPreflop();
    this.history.push(begin)
    this.curHandState = begin;
    return this.curHandState
  }

  private handleFlop(line : Line) {
    if(this.curRoundFinished()){
    this.line.round = Round.FLOP
    let beginPre = this.curHandState.beginnFlop(line.board.slice(0,3));
    this.history.push(beginPre)
    this.curHandState = beginPre;
    }
  }
  
  private handleTurn(line : Line) {
    if(this.curRoundFinished()){
    this.line.round = Round.TURN
    let beginPre = this.curHandState.beginnTurn(line.board[3]);
    this.history.push(beginPre)
    this.curHandState = beginPre;
    }
  }
  
  private handleRiver(line : Line) {
    if(this.curRoundFinished()){
    this.line.round = Round.RIVER
    let beginPre = this.curHandState.beginnRiver(line.board[4]);
    this.history.push(beginPre)
    this.curHandState = beginPre;
    }
  }

  private handleShowdown(line : Line) {
    if(this.curRoundFinished()){
      this.line.round = Round.SHOWDOWN
      let riverPlayersTurn = this.curHandState.beginnShowdown(line.riverChecksThrough());
      this.curHandState = riverPlayersTurn
      this.history.push(riverPlayersTurn)
    }
  }
  
  private handleRoundEnd() {
    if(this.curHandState.roundState.isBettingFinished && ! this.curHandState.isFinished){
      this.curHandState = this.curHandState.finishRound()
      this.history.push(this.curHandState)
    }
  }

  public curRoundFinished() {
    return this.curHandState.isCurrentRoundFinished
  }

  public handleNewAction(action: ActionBase) : Array<Hand> {
    if (this.curHandState.roundState.round == Round.SHOWDOWN ||
      action instanceof Muck || action instanceof Show) {
      let acCasted : Show | Muck = <Show | Muck> action
      this.onNewShowdownAction(acCasted);
    }
    else {
      this.onNewAction(action);
    }
    return this.history
  }

  public handleNewGameAction(ga : GameAction) : Array<Hand>  {
    if(!this.curRoundFinished()){
      throw new Error("can't change game state because round not over")
    }
    this.handleRoundEnd();
    
    console.log("round " + this.curHandState.roundState.round);
    switch (this.curHandState.roundState.round) {
      case Round.NOT_STARTED:
        if(ga instanceof StartHand){
          this.handlePreflop()
        } else {
          throw new Error("need to set the whole Cards");
        }
      case Round.PREFLOP: 
      if(ga instanceof DealFlop){
        let cardsBoard = ga.flop
        this.line.board = cardsBoard;
        if (cardsBoard.length < 3) {
          throw new Error("need to set flop!");
        }
        this.handleFlop(this.line);
      } else {
        throw new Error("We need a flop to proceed the game!");
      }
      break;
      case Round.FLOP:
        if(ga instanceof DealTurn){
          let cardsBoard = this.line.board.concat([ga.turn])
          this.line.board = cardsBoard;
          if (cardsBoard.length < 4) {
            throw new Error("need to set turn!");
          }
          this.handleTurn(this.line);
        } else {
          throw new Error("We need a turn to proceed the game!");
        }
        break;
      case Round.TURN:
        if(ga instanceof DealRiver){
          let cardsBoard = this.line.board.concat([ga.river])
          this.line.board = cardsBoard;
          if (cardsBoard.length < 5) {
            throw new Error("need to set river!");
          }
          
          this.handleRiver(this.line);
        } else {
          throw new Error("We need a river to proceed the game!");
        }
        break;
      case Round.RIVER:
        if(ga instanceof StartShowdown){
          this.handleShowdown(this.line);
        } else {
          throw new Error("We need to start the showdown proceed the game!");
        }
        break;
      case Round.SHOWDOWN:
        if(ga instanceof DeclareWinner){
          let cur : Array<Hand> = finishHandIfPossible(this.curHandState)
          this.history = this.history.concat(cur)
          this.curHandState = this.history[this.history.length - 1]
        } else {
          throw new Error("We need the whole cards to evaluate the game!");
        }
        break;
      default: 
        throw new Error("Did not expact such an event: " + typeof(ga))
    }
    return this.history
  }

  onNewAction(action : ActionBase){
    this.line.addToCurrentLine(action)
    let newAction = handleActionForState(this.curHandState)(action)
    this.history = this.history.concat(newAction)
    this.curHandState = this.history[this.history.length - 1]
  }

  onNewShowdownAction(action : Muck | Show){
    this.line.addToCurrentLine(action)
    let newAction : Array<Hand> = handleShowdownAction(this.curHandState)(action)
    this.history = this.history.concat(newAction)
    this.curHandState = this.history[this.history.length - 1]
  }

}