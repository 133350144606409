import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OverlayComponent implements OnInit {

  @Input()
  overlayText : String = "asdf"

  constructor() { }

  ngOnInit(): void {
  }

}
