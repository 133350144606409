import { Show, Muck, ActionBase, Call, Check, Fold, Raise, Bet, AllIn } from "src/app/pkr-table/shared/action";
import { Hand } from "src/app/pkr-table/shared/hand";
import { Player } from 'src/app/pkr-table/shared/player';
import { Blinds } from 'src/app/pkr-table/shared/pot';

export function handleShowdownAction(curHandState : Hand): (value: Show | Muck) => Array<Hand> {
    let history = []
    return (action: ActionBase) => {
      if (action instanceof Show) {
        let ac = curHandState.show(action.cards);
        let reset = ac.nextPlayer();
        history.push(ac);
        history.push(reset);
      }
      if (action instanceof Muck) {
        let ac = curHandState.muck();
        history.push(ac);
        if (ac.isSomebodyWon) {
          let roundEnd = handleRoundEnd(ac)
          history.push(roundEnd)
          let pic = roundEnd.finishHand();
          history.push(pic);
        } 
        else {  
          let reset = ac.nextPlayer();
          history.push(reset);
        }
      }
      return history
    };
  }

  function handleRoundEnd(curHandState : Hand) : Hand {
    if(curHandState.roundState.isBettingFinished && ! curHandState.isFinished){
      return curHandState.finishRound()
    } else {
        throw new Error("I guess this can't be reached...")
    }
  }

  export function finishHandIfPossible(state : Hand) : Array<Hand> {
    console.log("called big finsh")
    if (state.isEndShowdown) {
      return  state.finishHand()

    } else {
      console.log("could not be finished....")
    }
    return []
  }

export function handleActionForState(curHandState : Hand) : (ActionBase) => Array<Hand> {
  let history : Array<Hand> = []
  return (action) => {
  if (action instanceof Call) {
      let called = curHandState.call();
      let reset = called.nextPlayer();
      history.push(called);
      history.push(reset);
    }
    if (action instanceof Check) {
      let check = curHandState.check();
      let reset = check.nextPlayer();
      history.push(check);
      history.push(reset);
    }
    if (action instanceof Fold) {
      let ac = curHandState.fold();
      curHandState = ac;
      history.push(ac);
      if (ac.isSomebodyWon) {
        curHandState = handleRoundEnd(ac)
        let pics : Array<Hand> = curHandState.finishHand();
        history = history.concat(pics)
      } 
      else {  
        if(!curHandState.roundState.isBettingFinished) {
          let reset = ac.nextPlayer();        
          history.push(reset);
        }
      }
    }
    if (action instanceof Raise) {
      let ac = curHandState.raise(action.amount);
      let reset = ac.nextPlayer();
      history.push(ac);
      history.push(reset);
    }
    if (action instanceof Bet) {
      let ac = curHandState.bet(action.amount);
      let reset = ac.nextPlayer();
      history.push(ac);
      history.push(reset);
    }
    if (action instanceof AllIn) {
      let ac = curHandState.allIn();
      let reset = ac.nextPlayer(); 
      history.push(ac);
      history.push(reset);
    }
    return history
  }
}

export function handleBlindWagers(players: Player[], blinds: Blinds) {
  let handState = Hand.forTexasHoldemBasic(players, blinds);
  handState.validateAndInit();
  let antePic = handState.letPlayersPostAntes();
  let finishAnteRound = antePic.finishRound();
  let blindsPic = finishAnteRound.letPlayersPostBlinds()
  return [handState, antePic, finishAnteRound, blindsPic,];
}