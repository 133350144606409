export enum OrientationHorizontal {
    TOP = "top",
    BOTTOM = "bottom",
    MID = "mid"
}

export enum OrientationVertical {
    LEFT = "left",
    RIGHT = "right",
    MID = "mid"
}