import { Component, OnInit, Input, Attribute, HostBinding, ElementRef, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Player, PlayerState } from '../shared/player';
import { Suit, Rank } from "../shared/card";
import {OrientationHorizontal, OrientationVertical} from "./orientation"
import { IfStmt } from '@angular/compiler';
import { DomSanitizer } from '@angular/platform-browser';
import { isThisTypeNode } from 'typescript';
@Component({
  // avoiding element selectors for components in svg context
  // http://teropa.info/blog/2016/12/12/graphics-in-angular-2.html
  selector: '[player-svg]',
  templateUrl: './player-svg.component.html',
  styleUrls: ['./player-svg.component.css'],
})
export class PlayerSvgComponent implements OnInit, OnChanges {
   @Input('player') player: Player;

  constructor(private sanitizer: DomSanitizer, private el: ElementRef){
  }

  @Input("item")
  point : object;

  @Input()
  showWagerDesc : boolean

  @Input ()
  cardsSize : Number;

  @Input()
  triangleSize: Number;

  @Input() bbSize : number;

  @Input()
  displayInBB: Boolean;

  @Output() isSetToBB = new EventEmitter<Boolean>();

  unit : String; 

  globalPlayerNameSize : number

  ngOnInit() {
    let globalPlayerNameSizeAsPixel = window.getComputedStyle(this.el.nativeElement)
                                            .getPropertyValue('--fontsize-playername');
    if(globalPlayerNameSizeAsPixel && globalPlayerNameSizeAsPixel.endsWith("px")){
      let parsable = globalPlayerNameSizeAsPixel.substring(0, globalPlayerNameSizeAsPixel.length - 2)
      this.globalPlayerNameSize = parseFloat(globalPlayerNameSizeAsPixel.substring(0, globalPlayerNameSizeAsPixel.length - 2))
    }
    this.setUnit();1
  }

  private setUnit() {
    if (this.displayInBB) {
      this.setUnitToBB();
    } else {
      this.unit = this.player.stack.unit;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if("displayInBB" in changes){
      this.setUnit();
    }
  }

  get oH() : OrientationHorizontal{
    let middle : number = 42.5;
    if(this.point["y"] > middle){
      return OrientationHorizontal.BOTTOM
    } else if(this.point["y"] < middle){
      return OrientationHorizontal.TOP
    } else {
      return OrientationHorizontal.MID
    }
  };
  
  
  get oV() : OrientationVertical {
    let middle : number = 45;
    if(this.point["x"] > middle){
      return OrientationVertical.RIGHT
    } else if(this.point["x"] < middle){
      return OrientationVertical.LEFT
    } else {
      return OrientationVertical.MID
    }
  }

  get playerStackSize(): number {
    if (this.displayInBB) {
      return this.player.stack.size / this.bbSize
    } else {
      if (this.player.stack.unit in ['$','€']) {
        return this.player.stack.size / 100;
      } else {
        return this.player.stack.size;

      }
    }
  }
    
  // https://medium.com/@ingobrk/using-css-variables-in-angular-282a9edf1a20
  @HostBinding("attr.style")
  public get valueAsStyle(): any { 
    if(this.player.name.length > 8){
      return this.sanitizer.bypassSecurityTrustStyle(`
    --fontsize-playername: ${this.playerNameFontSize}px;
    `)
    }
    return;             
  }

  get playerNameFontSize() : number {
    let fraction = this.globalPlayerNameSize / 26 
    return fraction * 5 +  this.globalPlayerNameSize - (fraction * this.player.name.length) ; 
  }

  getPlayerPicId(){
      return this.player.name.replace(/\s/g, "") + "ID"
  }

  doesAction() : Boolean {
    return this.player.doesAction()
    }

  toggleBBs() : void {
    this.displayInBB = !this.displayInBB;
    this.isSetToBB.emit(this.displayInBB)
  }

  private setUnitToBB() {
    this.unit = "BB";
  }

  get cardsX() : number {
    return 10;
  }

  get cardsY() : number {
    if(this.oH == OrientationHorizontal.BOTTOM){
      return -10;
    } else {
      return 107.5
    } 
  }

  get buttonPoint() : [number,number] {
    switch(this.oH){
      case OrientationHorizontal.TOP:
        switch(this.oV){
          case OrientationVertical.LEFT: return [60,100]; //return [60,60];
          case OrientationVertical.RIGHT: return [-15,100];
          case OrientationVertical.MID:  return [24,119];
        }
      case OrientationHorizontal.BOTTOM:
        switch(this.oV){
          case OrientationVertical.LEFT: return [60,-25];
          case OrientationVertical.RIGHT: return [-15,-25];
          case OrientationVertical.MID:  return [24,-78];
        }
      case OrientationHorizontal.MID:
        switch(this.oV){
          case OrientationVertical.LEFT:return [70,30];
          case OrientationVertical.RIGHT: return [-25,30];
          case OrientationVertical.MID:  return;
        }
    }
  }

  get trinanglePoint() : [number,number] {
    let coordButton = this.buttonPoint

    switch(this.oH){
      case OrientationHorizontal.TOP:
        switch(this.oV){
          case OrientationVertical.LEFT:return [23, 110]; 
          case OrientationVertical.RIGHT: return [23, 110]; 
          case OrientationVertical.MID:  
            coordButton[0] -= 25;
            return coordButton;
        }
      case OrientationHorizontal.BOTTOM:
        switch(this.oV){
          case OrientationVertical.LEFT: coordButton[1] += 30
          return coordButton
          case OrientationVertical.RIGHT: coordButton[1] += 30
          return coordButton
          case OrientationVertical.MID: 
          coordButton[0] += 25;
          return coordButton;
        }
      case OrientationHorizontal.MID:
        switch(this.oV){
          case OrientationVertical.LEFT:return [70,60];
          case OrientationVertical.RIGHT: return [-25,60];
          case OrientationVertical.MID:  return;


      }
    
    }

    
  }

  get wagerPoint() : [number,number] {
    if(this.player.isButton){
      switch(this.oH){
        case OrientationHorizontal.TOP:
          switch(this.oV){
            case OrientationVertical.LEFT:return [69,79];
            case OrientationVertical.RIGHT: return [-15,79];
            case OrientationVertical.MID:  return [57,119];
          }
        case OrientationHorizontal.BOTTOM:
          switch(this.oV){
            case OrientationVertical.LEFT: return [69,-48];
            case OrientationVertical.RIGHT: return [-8,-48];
            case OrientationVertical.MID:  return [7,-69];
          }
        case OrientationHorizontal.MID:
          switch(this.oV){
            case OrientationVertical.LEFT:return [80,10];
            case OrientationVertical.RIGHT: return [-25,10];
            case OrientationVertical.MID:  return;
          }
      }
    } else {
      return this.buttonPoint;
    }
  }
    

  
}
