import { Line } from './lines';
import { Hand, Round } from 'src/app/pkr-table/shared/hand';
import { GameAction, Action, ActionBase, SetBlinds, SetPlayers, StartHand, SetStack, SetButton, DealCards, DealFlop, DealTurn, DealRiver, StartShowdown } from 'src/app/pkr-table/shared/action';
import { Blinds } from 'src/app/pkr-table/shared/pot';

export interface HandHistoryService {
    getHistory() : Array<Hand>;
}

export abstract class ApplicationState {
    constructor(public line : Line, public handState : Hand){}
}

export class Review extends ApplicationState {
    public static FromStringRepresentation(repr : string) : Review {
        let history = repr.split(",").filter( e => e ) // filter for empty string
        if(history.length <= 1){
            throw new Error("not enough commands for review")
        }
        let commands: Array<GameAction | ActionBase> = Review.collectCommands(history);
        let returnable = Review.handleCommands(commands);
        return returnable
    }

    private static collectCommands(history: string[]) {
        let commands: Array<GameAction | ActionBase> = [];
        history.forEach((value: string) => {
            let trimed = value.trim();
            let command = trimed.split(" ")[0].trim();
            if (command.length == 1) {
                commands.push(ActionBase.fromAction(trimed));
            }
            else {
                commands.push(GameAction.fromGameAction(trimed));
            }
        });
        return commands;
    }

    private static handleCommands(commands: (GameAction | ActionBase)[]) {
        let blinds: Blinds;
        let hand: Hand;
        let line: Line = Line.createEmpty();
        let handInitialized = false;
        commands.forEach((value: GameAction | ActionBase) => {
            if (value instanceof GameAction) {
                if (value instanceof SetBlinds) {
                    blinds = value.b;
                }
                else if (value instanceof SetPlayers) {
                    if (hand != null) {
                        throw new Error("set players multiple!");
                    }
                    hand = Hand.forTexasHoldemBasic(value.players, blinds, 0);
                }
                else if (value instanceof SetStack) {
                    hand.getPlayerByName(value.stacks[0]).stack.size = value.stacks[1];
                }
                else if (value instanceof SetButton) {
                    hand.getPlayerByName(value.playerName).isButton = true;
                }
                else if (value instanceof DealCards) {
                    hand.getPlayerByName(value.playerName).wholeCards = value.wholeCards;
                }
                else if (value instanceof StartHand) {
                    handInitialized = true;
                    line.round = Round.PREFLOP;
                }
                else if (value instanceof DealFlop) {
                    line.board = value.flop;
                    line.round = Round.FLOP;
                }
                else if (value instanceof DealTurn) {
                    line.board.push(value.turn);
                    line.round = Round.TURN;
                }
                else if (value instanceof DealRiver) {
                    line.board.push(value.river);
                    line.round = Round.RIVER;
                }
                else if (value instanceof StartShowdown) {
                    line.round = Round.SHOWDOWN;
                }
            }
            else if (handInitialized) {
                line.addToCurrentLine(value);
            }
            else {
                throw new Error("hand not started but line action found!");
            }
        });
        return new Review(line, hand);
    }
}


export class Lab extends ApplicationState {

}

export class Slave extends ApplicationState {

}