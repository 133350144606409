import { Injectable } from '@angular/core';
import { Rank, Suit } from "../../shared/card";

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  constructor() { }

  cardToUnicodeChar(rank : Rank, suit : Suit) : string {
    let base = 0x1F0A0;
    switch(suit){
      case Suit.HEART:
        base = 0x1F0B0;
        break;
      case Suit.DIAMOND:
        base = 0x1F0C0;
        break;
      case Suit.CLUB:
        base = 0x1F0D0;
        break;
      default:
        break;
    }
    switch(rank as Rank){
      case Rank.ACE:   base += 1; break
      case Rank.DEUCE: base += 2; break
      case Rank.THREE: base += 3; break
      case Rank.FOUR:  base += 4; break
      case Rank.FIVE:  base += 5; break
      case Rank.SIX:   base += 6; break
      case Rank.SEVEN: base += 7; break
      case Rank.EIGHT: base += 8; break
      case Rank.NINE:  base += 9; break
      case Rank.TEN:   base += 10; break
      case Rank.JACK:  base += 11; break
      case Rank.QUEEN: base += 13; break
      case Rank.KING:  base += 14; break
    }
    return String.fromCodePoint(base);
  }
}
