import { Component, OnInit, Input } from '@angular/core';
import { UnitNumber } from '../shared/player';

@Component({
  selector: '[wager]',
  templateUrl: './wager.component.html',
  styleUrls: ['./wager.component.css']
})
export class WagerComponent implements OnInit {

//TODO ADD BB for show in BB

  @Input()
  isHorizontal : Boolean = true;

  @Input()
  amount : UnitNumber
  
  @Input() 
  bars : Number

  @Input()
  chips : Number

  @Input()
  showWagerDesc : boolean

  constructor() { }

  ngOnInit() {
  
  }

}
