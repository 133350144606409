import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';

import { ActionBase, Show } from "../pkr-table/shared/action";
import { Rank, Suit, WholeCards } from "../pkr-table/shared/card";
import { BetSliderComponent } from './bet-slider/bet-slider.component';


@Component({
  selector: 'action-control',
  templateUrl: './action-control.component.html',
  styleUrls: ['./action-control.component.css']
})
export class ActionControlComponent implements OnInit {

  @Output() clickAction = new EventEmitter<ActionBase>();

  @Input() isShowdown  : boolean = false
  constructor() { }

  @Input() canCheck  : boolean = false
  
  @Input() canCall  : boolean = false

  @Input() canFold  : boolean = false

  @Input() isBet : boolean = true


  display : boolean

  @Input()
  min : number = 0

  @Input()
  max : number = 0

  @Input()
  wholeCards : WholeCards = [[Rank.UNKNOWN, Suit.UNKNOWN],[Rank.UNKNOWN, Suit.UNKNOWN]]

  @ViewChild('bslider') betSlider : BetSliderComponent;

  heightCardPicker : number = 800

  cardsActivated : Array<[Rank, Suit]>

  get wagerValue() : number {
    return this.betSlider.val
  }

  ngOnInit() {
    
  }

  notify(action : string){
    if("s" == action[0]){
      this.clickAction.emit(new Show(this.wholeCards))
    } else {
      this.clickAction.emit(ActionBase.fromAction(action))
    }
  }

  onCardsActivated(a : Array<[Rank,Suit]> ) {
    this.cardsActivated = a
  }

}
