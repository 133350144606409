import { Pipe, PipeTransform } from '@angular/core';
import { Currency, isMoney } from './shared/pot';

@Pipe({
  name: 'amountFormat'
})
export class AmountFormatPipe implements PipeTransform {

  calcDigits(value : number) : number {
    return value.toString().length
  }

  // TODO fixme
  transform(value: number, any?: Currency): string {
    let digits = this.calcDigits(value)
    let returnable = ""
    let valueAsString = value.toString().split("").reverse()
    let hasFraction = valueAsString[0] != "0" || valueAsString[1] != "0"
    if (isMoney(any)) {
      if(digits > 2){
        value = value / 100
      } 
      switch(digits){
        // commas
        case 1: returnable += `0.0${value}`; break
        case 2: returnable += `0.${value}`; break
        // normal values
        case 3: returnable += `${value}`; break
  
        case 4: returnable += `${value}`; break
  
        // remove fractions
  
        case 5: returnable += `${value.toString().substring(0,5) }`; break
        case 6: returnable += `${value.toString().substring(0,4) }`; break
        case 7: returnable += `${value.toString().substring(0,5) }`; break
        case 8: returnable += value.toString().substring(0,3) + 'k'; break;
        
        case 9: returnable += value.toString().substring(0,4) + 'k'; break;
        case 10: returnable += value.toString().substring(0,5) + 'k'; break;
        case 11: returnable += value.toString().substring(0,6) + 'k'; break;
      }
     
    } else {
      switch(digits){
        // commas
        
        case 7: returnable += value.toString().substring(0,4) + 'k'; break;
        case 8: returnable += value.toString().substring(0,5) + 'k'; break;
        case 9: returnable += value.toString().substring(0,6) + 'k'; break;
  
        default: returnable += value.toString()
  
      }
          
  
      }
    

    return returnable;
    
  }

}
