/// <reference path = "../../../phe.d.ts" />
import  * as Phe  from 'phe/phe.js';


import { Currency } from './pot';
import { DeepCopy } from './clone';
import { Rank, Suit, WholeCards, Card } from './card';
import { range, zip } from './helpers';

enum CONSTANTS {
  DEFAULT_USER_LOC =  "assets/user.png",
  DEFAULT_DEALER_BUTTON = "assets/dealer.jpg"
}

export class UnitNumber {
  size: number = 0;
  unit: Currency = Currency.PLAY;

  constructor(size : number = 0, unit : Currency = Currency.PLAY){
    this.size = size
    this.unit = unit
  }

  getUnit() : string {
    return this.unit.toString()
  }
}

export enum PlayerState {
  SIT_OUT = "sitout",
  FOLDED = "folded",
  ACTIVE = "active",
  INVOLVED = "involved",
  IS_ALL_IN = "involved",

  SHOWED = "show",
  MUCKED = "muck",
  WON = "won",
  LOST = "lost",

  FOLDS = "fold",
  RAISED = "raise",
  CALLED = "call",

  BET = "bet",
  ALL_IN = "all-in",
  CHECKED = "check",

  PLACED_ANTE = "ante",
  PLACED_SB = "sb",
  PLACED_BB = "bb",
  PLACED_STRADDLE = "straddle",
}

export class Player extends DeepCopy<Player> {
  
  static DEFAULT_WHOLECARDS : WholeCards = [[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]]

  static sortToButton(input: Array<Player> ) : Array<[Player, number]> {
    var zippedInputWithIndex : Array<[Player, number]> = zip(input, range(0, input.length))
    var indexButtonPair : [Player, number] = zippedInputWithIndex.filter(p => p[0].isButton)[0]
    var indexButton : number  = indexButtonPair[1]
    return zippedInputWithIndex.slice(indexButton + 1, input.length).concat(zippedInputWithIndex.slice(0, indexButton + 1))
  }

  fromStringArray(input : Array<string>) : Array<Player>{
    return input.map((playerName) => new Player(playerName, new UnitNumber(), PlayerState.INVOLVED, Player.DEFAULT_WHOLECARDS,0,false))
  }

  amountInPlayTotal : number = 0 
  isSitout : boolean = false

  constructor(public name : string, // this is unique
              public stack : UnitNumber, 
              public state : PlayerState, 
              public wholeCards : WholeCards, 
              public amountInPlay : number, 
              public isButton : boolean, 
              public image : string = CONSTANTS.DEFAULT_USER_LOC){
      super()
  }

  getAmountInPlayWithUnit() : UnitNumber {
    return new UnitNumber(this.amountInPlayTotal, this.stack.unit)
  }

  get isAllIn() : boolean  {
    return this.stack.size == 0
  }
  putInPlay(amount : number):void{
    if(amount > this.stack.size){
      throw new Error("amount to big for the size")
    }
    this.amountInPlayTotal += amount
    this.amountInPlay += amount
    this.stack.size -= amount
  }

  putInPlayUntil(amount : number):void{
    if(amount > this.stack.size){
      amount = this.stack.size
    }
    this.putInPlay(amount)
  }

  isOut() : boolean {
    return this.state == PlayerState.LOST || this.state == PlayerState.FOLDED || this.state == PlayerState.FOLDS || this.state == PlayerState.SIT_OUT || this.state == PlayerState.MUCKED 
  }
  isOutOrShowed() : boolean {
    return this.isOut() || this.state == PlayerState.SHOWED
  }
  isFinishState() : boolean {
    return this.state == PlayerState.WON || this.state == PlayerState.LOST
  }

  isShowdownState() : boolean {
    return this.state == PlayerState.SHOWED || this.state == PlayerState.MUCKED
  }

  performCall(amount : number) : void {
    this.putInPlayUntil(amount - this.amountInPlay)
    this.state = PlayerState.CALLED
  }

  performRaise(amount : number) : void {
    this.putInPlayUntil(amount - this.amountInPlay)
    if(this.stack.size == 0){
      this.state = PlayerState.ALL_IN
    } else {
      this.state = PlayerState.RAISED
    }
  }

  performBet(amount : number) : void {
    this.putInPlayUntil(amount - this.amountInPlay)
    if(this.stack.size == 0){
      this.state = PlayerState.ALL_IN
    } else {
      this.state = PlayerState.BET
    }
  }

  toggleSitout() : void {
    this.isSitout = !this.isSitout
    if(this.isSitout){
      this.state = PlayerState.SIT_OUT
    }
  }

  performFold() : void {
    if(this.isAllIn){
      throw new Error("player can't fold when allIn")
    }
    this.state = PlayerState.FOLDS
  }

  performCheck() : void {
    if(this.isAllIn){
      throw new Error("player can't check when allIn")
    }
    this.state = PlayerState.CHECKED
  }

  setFoldedForThisHand() : void {
    this.state = PlayerState.FOLDED
  }

  getTotal() : number {
    return this.amountInPlay + this.stack.size
  }

  doesAction() : boolean {
    return [PlayerState.ALL_IN, 
            PlayerState.BET, 
            PlayerState.RAISED, 
            PlayerState.CALLED, 
            PlayerState.CHECKED, 
            PlayerState.SHOWED, 
            PlayerState.FOLDS, 
            PlayerState.MUCKED, 
            PlayerState.WON,
            PlayerState.LOST,
            PlayerState.PLACED_SB, 
            PlayerState.PLACED_ANTE, 
            PlayerState.PLACED_BB, 
            PlayerState.PLACED_STRADDLE,
            ].includes(this.state)
  }
  
  isUnknown(c : Card){
    return c[0] == Rank.UNKNOWN || c[1] == Suit.UNKNOWN
  }

  evaluateHand(board : Array<Card>) : number {
    // see https://www.typescriptlang.org/docs/handbook/modules.html
    // why Phe should have typed functions
    if(this.isUnknown(this.wholeCards[0]) || this.isUnknown(this.wholeCards[1])){
      return Number.MAX_VALUE
    } else {
      return Phe.evaluateCards(board.concat(this.wholeCards))
    }
  }
}
