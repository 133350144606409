import { Player, PlayerState, UnitNumber } from './player';
import { Rank, Suit, cardFromString } from "./card";
import { Hand } from "./hand";
import { Pot, Currency, Blinds, GeneralAnte, BigBlindAnte, UTGStraddle, NoAnte } from "./pot";
import { LayoutConfig } from "./config";
import { Line } from 'src/app/pkr-texas-holdem/shared/lines';
import { AllIn, Call, Show, Fold, Raise, Bet, Check } from './action';

// test player



export const PLAYER_FOLDED =new Player("Unknown", new UnitNumber(10000),PlayerState.FOLDED,
[[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], 9992100, false)

export const DANIEL_NEGREANU : Player =
new Player("Daniel Negreanu", new UnitNumber(10000),PlayerState.INVOLVED,
[[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], 0, true, "https://media.cardplayer.com/assets/000/025/144/56805scr_584868772dde42f.jpg")

export const SUPER_POS_PLAYER : Player = 
new Player("Superposasdfasdfasfd", new UnitNumber(0),PlayerState.INVOLVED,
[[Rank.DEUCE, Suit.SPADE], [Rank.ACE, Suit.HEART]], 99999999, true, "https://pbs.twimg.com/profile_images/1203412981746479105/Lv72wI5B_400x400.jpg");

export const PLAYER_ALL_IN : Player = new Player("Lower", new UnitNumber(1100),PlayerState.INVOLVED,
[[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], 0, false)

export const PLAYER_ALL_IN2 : Player = new Player("Low", new UnitNumber(2100),PlayerState.INVOLVED,
[[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], 0, false)

export const PLAYER_ALL_IN_ANTE : Player = new Player("Anteallin", new UnitNumber(80),PlayerState.INVOLVED,
[[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], 0, false)

export const PLAYER_ALL_IN_BLINDS : Player = new Player("Blindallin", new UnitNumber(150),PlayerState.INVOLVED,
[[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], 0, false)

export const PLAYER_UNKNOWN : Player = new Player("Unknown111", new UnitNumber(10000),PlayerState.INVOLVED,
[[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], 0, false);

// helper functions

export function createPlayerWith(name : string, stack: number, cards : [[Rank, Suit], [Rank, Suit]] = [[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], isButton : boolean = false )   : Player {
  let returnable = PLAYER_UNKNOWN.clone()
  returnable.stack.size = stack
  returnable.name = name
  returnable.wholeCards = cards
  returnable.isButton = isButton
  return returnable
}

export function createPlayerSittingOutWith(name : string, stack: number, cards : [[Rank, Suit], [Rank, Suit]] = [[Rank.UNKNOWN, Suit.UNKNOWN], [Rank.UNKNOWN, Suit.UNKNOWN]], isButton : boolean = false )   : Player {
  let returnable = PLAYER_UNKNOWN.clone()
  returnable.stack.size = stack
  returnable.name = name
  returnable.wholeCards = cards
  returnable.isButton = isButton
  returnable.toggleSitout()
  return returnable
}


export function createPlayerWithInPlay(amount : number) : Player {
  let ret : Player = PLAYER_ALL_IN.clone()
  ret.amountInPlay = amount
  return ret
}


export const SUPER_POS : Hand = new Hand([[Rank.SIX, Suit.HEART], [Rank.FIVE, Suit.DIAMOND], [Rank.FOUR, Suit.DIAMOND], [Rank.THREE, Suit.DIAMOND], [Rank.ACE, Suit.DIAMOND]],
  [
    DANIEL_NEGREANU,  PLAYER_UNKNOWN, PLAYER_UNKNOWN, PLAYER_UNKNOWN , PLAYER_UNKNOWN, PLAYER_UNKNOWN, PLAYER_UNKNOWN, PLAYER_UNKNOWN, PLAYER_UNKNOWN, PLAYER_UNKNOWN
  ],
  new Pot(999999),
  null
  )

export function generateSuperPosAll(playerCount : Number) : Hand {
let returnable : Hand = SUPER_POS.clone()
returnable.players = [];
let sidepots = []

let states = Object.entries(PlayerState).map(e => ({ name: e[1], id: e[0] }));
for(let i = 0; i < playerCount; i++){
  let player : Player = SUPER_POS_PLAYER.clone()
  let playerState = PlayerState.ACTIVE;//states[i]
  player.stack = new UnitNumber()
  player.state = playerState
  player.name = "AAAAAA" + ("AA".repeat(i + 1))
  player.stack.size = player.name.length;
  returnable.players.push(player)
  if (i > 1){
    let sidepotAmount = Math.pow(10, i) + 1
    returnable.pot.sidepots.push([sidepotAmount, [player]])
  }
}
return returnable;
}

export function handFor(playerCount : number) : Hand {
let pot = 1;
let board = [];
let players = [];
let items = [PLAYER_FOLDED, PLAYER_UNKNOWN, PLAYER_ALL_IN]
let sidepots = []
let first = PLAYER_UNKNOWN
players.push(first);
for(let i = 1; i < playerCount; i++){
  createPlayerWithInPlay
  let pushable = items[Math.floor(Math.random() * items.length)]
  if(pushable == PLAYER_ALL_IN){
    pushable = createPlayerWithInPlay(Math.pow(10, playerCount))
    //sidepots.push(new Sidepot([first, pushable], 101))
  } 
  players.push(pushable) 
  
}
pot =  Math.pow(10, playerCount) - 1
let returnable : Hand = new Hand(board, players, new Pot(pot), null)

return returnable;
}

export const EXAMPLE_HAND = {
board: [[Rank.SIX, Suit.HEART], [Rank.FIVE, Suit.DIAMOND], [Rank.FOUR, Suit.DIAMOND], [Rank.THREE, Suit.DIAMOND], [Rank.ACE, Suit.DIAMOND]],
players: [
  DANIEL_NEGREANU,  PLAYER_FOLDED, PLAYER_FOLDED, PLAYER_FOLDED , PLAYER_FOLDED, PLAYER_FOLDED, PLAYER_FOLDED, PLAYER_UNKNOWN, PLAYER_FOLDED, PLAYER_FOLDED
],
pot: 1234400

}

export const HEADS_UP_PRE : Hand = <Hand>{
board: [],
players: [
//  DANIEL_NEGREANU,  PLAYER_ALL_IN_BLINDS, PLAYER_ALL_IN, PLAYER_ALL_IN_ANTE, PLAYER_ALL_IN2, PLAYER_UNKNOWN
  PLAYER_UNKNOWN, DANIEL_NEGREANU
],
pot: new Pot(0),
blinds : new Blinds(5000,10000, null, new GeneralAnte(10000)),
layoutConfig: new LayoutConfig

}
export const HAND_SIX_PLAYERS : Hand = <Hand>{
  board: [],
  players: [
    DANIEL_NEGREANU,  PLAYER_ALL_IN_BLINDS, PLAYER_ALL_IN, PLAYER_ALL_IN_ANTE, PLAYER_ALL_IN2, PLAYER_UNKNOWN  
  ],
  pot: new Pot(0),
  blinds : new Blinds(50,100, null, new GeneralAnte(100)),
  layoutConfig: new LayoutConfig
}

export const PLAYER_2_FULL = {
board:[[Rank.SIX, Suit.HEART], [Rank.FIVE, Suit.DIAMOND], [Rank.FOUR, Suit.DIAMOND], [Rank.THREE, Suit.DIAMOND], [Rank.ACE, Suit.DIAMOND]],
players: [
  DANIEL_NEGREANU,  PLAYER_UNKNOWN
],
pot: 1234400

}

  

// Hands with Marcel Review 05.04.20

export const BLUFF_LSOP_BOSS : Hand = <Hand>{
  players: [
  
    createPlayerWith("marcelrth", 491000, [cardFromString("Qd"), cardFromString("9d")]),
    createPlayerSittingOutWith("crazylind", 498500),
    createPlayerWith("H1gHL1GhT", 509000, [cardFromString("Kc"), cardFromString("6c")], true),
    createPlayerSittingOutWith("TheBrain35", 509000),
    createPlayerWith("thetieguy11", 504500),
    createPlayerWith("6JohnPorno9", 500000),
    createPlayerWith("Maximizer0815", 500000),
  ],
  pot: new Pot(0),
  blinds : new Blinds(1500,3000, null, new NoAnte()),
  layoutConfig: new LayoutConfig
  
  }

export const gameAsString : string = `
blinds sb 1500 bb 3000,
players [marcelrth;crazylind;H1gHL1GhT;TheBrain35;thetieguy11;6JohnPorno9;Maximizer0815],
stack [marcelrth:491000],
stack [crazylind:498500],
stack [H1gHL1GhT:509000],
stack [TheBrain35:509000],
stack [thetieguy11:504500],
stack [6JohnPorno9:500000],
stack [Maximizer0815:500000],
button [H1gHL1GhT],
cards [H1gHL1GhT:Kc6c],
cards [marcelrth:Qd9c],
start,
f,f,r 7500, f, c, f, f,
flop [Tc2h9d], 
b 9000, r 27800, c,
turn [Ah],
x, x,
river [2d],
b 75100, c,
showdown,
s [Qd9c],s [KcTd]`;

function toFlat(a : Array<any>) {
  return [].concat.apply([], a);
}

  export const HAND_FLOW_BLUFF_LSOP_BOSS = new Line([],
    [new Fold(), new Fold(), new Raise(7500), new Call(), new Fold()],
    [new Bet(9000), new Raise(27800), new Call()],
                                    [new Check(), new Check()],
                                    [new Bet(75100), new Fold()],
                                    [],
                                    [cardFromString("Tc"), cardFromString("2h"), cardFromString("9d"), cardFromString("Ah"), cardFromString("2d")])
                                     
  
export const exampleTrippleBarrel : string = `
blinds sb 1 bb 2,
players [unknown1;unknown2;unknown3;unknown4;unknown5;H1gHL1GhT;unknown6;Hero;unknown7],
stack [unknown1:200],
stack [unknown2:200],
stack [unknown3:200],
stack [unknown4:200],
stack [unknown5:200],
stack [unknown6:200],
stack [unknown7:200],
stack [H1gHL1GhT:190],
stack [Hero:200],
button [unknown6],
cards [H1gHL1GhT:AdQd],
cards [Hero:QcQh],
start,
f, f, f, f, f, r 5, f, r 24, f, c,
flop [KhJh4d], 
x, b 16, c,
turn [2s],
x, b 40, c,
river [7d],
x, b 200, c,
showdown,
s [AdQd],s [QdQh]`;