import { Injectable } from '@angular/core';
import { Blinds } from '../pkr-table/shared/pot';
import { Hand, Round } from '../pkr-table/shared/hand';
import { Rank, Suit } from '../pkr-table/shared/card';
import { ActionBase, Call, Check, Fold, Raise, Bet, AllIn, Muck, Show } from '../pkr-table/shared/action';
import { Player } from '../pkr-table/shared/player';
import { Line } from './shared/lines';
import { HandHistoryService } from './shared/model';
import { handleShowdownAction, finishHandIfPossible, handleActionForState, handleBlindWagers } from './shared/handle_actions';

@Injectable({
  providedIn: 'root'
})
export class TexasHoldemStateService implements HandHistoryService {

  private curHandState : Hand 
  private history : Array<Hand>  = []

  constructor() { }

  getHistory(): Hand[] {
    return this.history
  }

  generatePicsForWholeHand(line : Line, blinds : Blinds, players : Array<Player>) : Array<Hand> {
    this.history = handleBlindWagers(players, blinds); // resets the array!
    this.curHandState = this.history[this.history.length - 1]

    // pre
    this.doAction(Round.PREFLOP, line, ()  => {
      this.handlePreflop(line)
      this.handleLines(line.preflop)
      this.handleRoundEnd()
    });

    // flop
    this.doAction(Round.FLOP, line, ()  => {
      this.handleFlop(line)
      this.handleLines(line.flop)
      this.handleRoundEnd()
    });

    // turn
    this.doAction(Round.TURN,line, ()  => {
      this.handleTurn(line)
      this.handleLines(line.turn)
      this.handleRoundEnd()
    });
    // river
    
    this.doAction(Round.RIVER,line,()  => {
      this.handleRiver(line)
      this.handleLines(line.river)
      this.handleRoundEnd()
    });
    // showdown
    this.doAction(Round.SHOWDOWN, line,()  => {
      this.handleShowdown(line)
      this.handleShowdownLines(line.showdown)
        let cur : Array<Hand> = finishHandIfPossible(this.curHandState)
        this.history = this.history.concat(cur)
        this.curHandState = this.history[this.history.length - 1]
    });
    return this.history
  }
  

  public handleBlindWagers(players: Player[], blinds: Blinds) {
    let handState = Hand.forTexasHoldemBasic(players, blinds);
    handState.validateAndInit();
    let antePic = handState.letPlayersPostAntes();
    let finishAnteRound = antePic.finishRound();
    let blindsPic = finishAnteRound.letPlayersPostBlinds();
    this.curHandState = blindsPic;
    return [handState, antePic, finishAnteRound, blindsPic,];
  }

  private doAction(r : Round, line : Line, actionMethod :  () => void ) : void{
    if(!this.curHandState.isSomebodyWon && line.calcRoundByInput() >= r){
      actionMethod()
    }
  }

  
  private handlePreflop(line : Line) {
    let begin = this.curHandState
                    .setPlayersCards(line.getWholeCards(this.curHandState.players.length))
                    .beginnPreflop();
    this.history.push(begin)
    this.curHandState = begin;
  }
  
  private handleFlop(line : Line) {
    if(this.curRoundFinished()){
    let beginPre = this.curHandState.beginnFlop(line.board.slice(0,3));
    this.history.push(beginPre)
    this.curHandState = beginPre;
    }
  }
  
  private handleTurn(line : Line) {
    if(this.curRoundFinished()){
    let beginPre = this.curHandState.beginnTurn(line.board[3]);
    this.history.push(beginPre)
    this.curHandState = beginPre;
    }
  }
  
  private handleRiver(line : Line) {
    if(this.curRoundFinished()){
    let beginPre = this.curHandState.beginnRiver(line.board[4]);
    this.history.push(beginPre)
    this.curHandState = beginPre;
    }
  }
  
    private handleShowdown(line : Line) {
      if(this.curRoundFinished()){

        let riverPlayersTurn = this.curHandState.beginnShowdown(line.riverChecksThrough());
        this.curHandState = riverPlayersTurn
        this.history.push(riverPlayersTurn)
      }
    }

  private handleRoundEnd() {
    if(this.curHandState.roundState.isBettingFinished && ! this.curHandState.isFinished){
      this.curHandState = this.curHandState.finishRound()
      this.history.push(this.curHandState)
    }
  }

  private handleLines(actions: ActionBase[]){
    actions.forEach((action) => {
      let newActions = handleActionForState(this.curHandState)(action)
      this.history = this.history.concat(newActions)
      this.curHandState = this.history[this.history.length - 1]
    })
    if(this.curRoundFinished() && ! this.curHandState.isFinished){
      this.history.pop()
    }
  }

  private curRoundFinished() {
    return !this.curHandState.isSomebodyWon && this.curHandState.roundState.isBettingFinished;
  }

  private handleShowdownLines(actions: Array<Muck | Show>){
    actions.forEach((action) => {
      let newActions = handleShowdownAction(this.curHandState)(action)
      this.history = this.history.concat(newActions)
      this.curHandState = this.history[this.history.length - 1]
    } );
  }

}
