import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Player } from '../pkr-table/shared/player';
import { Hand, Round } from "../pkr-table/shared/hand";
import { Blinds } from "../pkr-table/shared/pot";
import { Line } from './shared/lines';
import { ActionBase, DealFlop, DealTurn, DealRiver, StartShowdown, DeclareWinner } from "../pkr-table/shared/action";
import { timer, Observable, Subscription } from 'rxjs';
import { REPLAY_STATE } from '../replay-control/domain';
import { ActionControlComponent } from '../action-control/action-control.component';
import { ApplicationState, Review, Lab, Slave, HandHistoryService } from './shared/model';
import { TexasHoldemStateService } from './texas-holdem-state.service';
import { TexasHoldemStateTrackerService } from './texas-holdem-state-tracker.service';
import { Rank, Suit, Card } from '../pkr-table/shared/card';
import { handleBlindWagers } from './shared/handle_actions';

@Component({
  selector: 'pkr-texas-holdem',
  templateUrl: './pkr-texas-holdem.component.html',
  styleUrls: ['./pkr-texas-holdem.component.css']
})
export class PkrTexasHoldemComponent implements OnInit {

  @Input() 
  state : ApplicationState
  players : Array<Player> 
  blinds : Blinds
  
  public curHandState : Hand 
  public history : Array<Hand>  = []
  public currentPic : number = 0
  
  private isReplaying : boolean = false
  private isSlave : boolean = false

  @ViewChild('actionComp') actionComponent : ActionControlComponent;

  widthPokerTable :  number = window.screen.width * 0.575

  constructor(private reviewGenerator : TexasHoldemStateService,
              private trackerService : TexasHoldemStateTrackerService) { }

  ngOnInit(){
    
    let players = this.state.handState.players;
    
    if(this.state instanceof Review){
      this.initReviewMode(players, this.state.handState, this.state.line);
    } else if(this.state instanceof Lab){
      this.initInteractiveMode(players, this.state.handState, this.state.line);   
    } else if(this.state instanceof Slave){
      this.isSlave = true
    }
  
  }

  initReviewMode(players: Player[], handState : Hand, line : Line) {
    this.history = this.reviewGenerator.generatePicsForWholeHand(line, handState.blinds, players);
    this.syncHistory(this.reviewGenerator);
  }

  initInteractiveMode(players : Array<Player>, handState : Hand, line : Line ) {
    let history = handleBlindWagers(players, handState.blinds); 
    let newHandState = history[history.length - 1]
    this.trackerService.init(newHandState, history, line);
    this.trackerService.handlePreflop();
    this.syncHistory(this.trackerService)
    this.oberserableTimer();
  }

  syncHistory(service : HandHistoryService) : void {
    this.history = service.getHistory()
    this.curHandState = this.history[this.history.length - 1]
  }

  onClickAction(action : ActionBase){
    
    try {
      this.history = this.trackerService.handleNewAction(action)
      this.syncHistory(this.trackerService)
      if(this.curHandState.isCurrentRoundFinished){
        switch (this.curHandState.roundState.round) {
          case Round.PREFLOP: 
            this.trackerService.handleNewGameAction(new DealFlop(<[Card, Card, Card]>this.actionComponent.cardsActivated))
            break;
          case Round.FLOP:
            this.trackerService.handleNewGameAction(new DealTurn(this.actionComponent.cardsActivated[3]))
            
            break;
          case Round.TURN:
            this.trackerService.handleNewGameAction(new DealRiver(this.actionComponent.cardsActivated[4]))
            
            break;
          case Round.RIVER:
            this.trackerService.handleNewGameAction(new StartShowdown())
            break;
          case Round.SHOWDOWN:
            this.trackerService.handleNewGameAction(new DeclareWinner([]))
            break;
          }
          this.syncHistory(this.trackerService)
      }
      this.oberserableTimer()
    } catch (error) {
      console.log(error)
      this.state.line.removeFromCurrentLine()
      console.log("remove last not sensible action")
    }
  }

  onClickReplay(state : REPLAY_STATE){
    switch(state){
      case REPLAY_STATE.PLAY:
        if(!this.subscription){
          this.oberserableTimer()
        } else {
          this.subscription = this.source.subscribe(this.incPicture());
        }
        this.isReplaying = true
        break;
      case REPLAY_STATE.PAUSE:
        this.subscription.unsubscribe()
        this.isReplaying = false
        break;
      case REPLAY_STATE.FORWARD:
        if(!this.isReplaying && this.currentPic < this.maxPic){
          this.currentPic++;
        }
        break;
      case REPLAY_STATE.BACKWARD:
        if(!this.isReplaying && this.currentPic > 0){
          this.currentPic--;
        }
        break;
    }

  }

  get maxPic() : number {
    return this.history.length - 1
  }
  
  source : Observable<number>;
  subscription : Subscription

  oberserableTimer() {
    if(!this.isReplaying){
      this.isReplaying = true
      this.source = timer(1000, 1000);
      this.subscription = this.source.subscribe(this.incPicture());
    }
    
  }

  oberserableTimerEndless() {
    if(!this.isReplaying){
      this.isReplaying = true
      this.source = timer(1000, 1000);
      this.subscription = this.source.subscribe((value: number) => {
        this.currentPic = (this.currentPic + 1) % this.history.length
      } );
    }
  }

  private incPicture(): (value: number) => void {
    return () => {
      if (this.currentPic < this.history.length - 1) {
        this.currentPic++;
      } else {
        this.subscription.unsubscribe()
        this.isReplaying = false
      }
    };
  }
}
