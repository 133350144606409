import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Player } from '../pkr-table/shared/player';
import { Rank, Suit, cardFromString as card } from "../pkr-table/shared/card";
import { Hand, Round } from "../pkr-table/shared/hand";
import { Blinds } from "../pkr-table/shared/pot";
@Component({
  selector: '[card-picker]',
  templateUrl: './card-picker.component.html',
  styleUrls: ['./card-picker.component.css']
})
export class CardPickerComponent implements OnInit {

  cards : Array<Array<[Rank, Suit]>>= []

  @Input("h")
  height = 600

  @Input()
  maxCards = 52

  @Input()
  cardsActivatedAtBeginning : Array<[Rank, Suit]> = []

   shouldMakeNonClickable(i: number) : Array<[Rank, Suit]> {
    if(this.getCardsInOrder.length < this.maxCards){
      return []
    } else {
      let makeNonClickable = this.cards[i].filter(c => {
        return this.cardRowActivatedCards[i].filter((d) => d[0] == c[0] && d[1] == c[1]).length == 0
      })
      return makeNonClickable
    }
  }

  shouldActivate(forRank : Rank ) : Array<[Rank, Suit]> {
    return this.cardsActivatedAtBeginning.filter((c) =>  (c[0] == forRank)) 
  }


  @Output() cardsActivated = new EventEmitter<Array<[Rank, Suit]>>();

  get viewBoxY() : number {
    if(this.height <= 600){
      return -5
    } else {
      return (this.height - 600) / 20
    }
  }

  get cs() : number {
    return this.height / 13 / 150
  }

  spaceHorizontal(i : number) : number {
    return i * this.height / 80
  }

  cardRowActivatedCards : Array<Array<[Rank, Suit]>> = []
  order : Array<[number, number]> = []

  get getCardsInOrder() : Array<[Rank,Suit]> {
    return this.order.map((value : [number, number])=> this.cardRowActivatedCards[value[0]][value[1]] )
                    
  }

  difference(oneArr, twoArr){
    var newArr = [];
    newArr = oneArr.filter((item)=>{
        return !twoArr.includes(item)
    });
      let arr = twoArr.filter((item)=>{
          return !oneArr.includes(item)
       });
      newArr =  newArr.concat(arr);
    return newArr
  }

  onCardRowActivated(i : number, a : Array<[Rank, Suit]>) {
    let curArray = this.cardRowActivatedCards[i]
    let hasRemovedSomething = curArray.length > a.length
    if(hasRemovedSomething){
      let diffElement = this.difference(curArray, a)[0]        
      let toDelete = curArray.indexOf(diffElement)
      let removeable : [number, number] = [i, toDelete]
      let removableOrderEntryIndex = -1
      for(let k = 0; k < this.order.length; k++){
        let e = this.order[k]
        if(e[0] == removeable[0]){
          if(e[1] == removeable[1]){
            removableOrderEntryIndex = k
            
          }
          if(e[1] > removeable[1]){
            e[1]--
          }
        }
      }
      
      if(removableOrderEntryIndex == -1){
        throw new Error("this shouldn't happen")
      } else {
        this.order.splice(removableOrderEntryIndex, 1)
      }
      
    } else {
      // has added something
      this.order.push([i, a.length - 1])
    }
    this.cardRowActivatedCards[i] = a
    this.cardsActivated.emit(this.getCardsInOrder)
  }

  constructor() {
    for(let i = 0; i < 13; i++){
      this.cardRowActivatedCards[i] = []
    }
   }

  ngOnInit() {  

    for (let r in Rank) {
      if(r != "UNKNOWN"){
        let a = []
        for (let s in Suit) {
          if(s != "UNKNOWN"){
            a.push([Rank[r],Suit[s]])
          }
        }
        this.cards.push(a)
      }
    }
  }

  
    
  

}
