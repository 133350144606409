
  

<p-sidebar [(visible)]="display">

    <div>
    <svg card-picker (cardsActivated)="onCardsActivated($event)" [maxCards]="5" attr.height="{{heightCardPicker}}px" [h]="heightCardPicker" >
    </svg>
  </div>
</p-sidebar>

<button type="text" (click)="display = true" pButton icon="pi pi-plus" label="Show"></button>

<div id="replay-container">
  <div>
    
    <span *ngIf="!isShowdown && canFold" (click)="notify('f')" class="material-icons">
      delete
    </span>

    <span *ngIf="isShowdown" (click)="notify('m')" class="material-icons">
      highlight_off
    </span> 

    <span *ngIf="!isShowdown && canCall" (click)="notify('c')" class="material-icons">
      done_outline
    </span>

    <span *ngIf="!isShowdown && canCheck" (click)="notify('x')" class="material-icons">
      done_outline
    </span>


    <span *ngIf="!isShowdown && isBet" (click)="notify('b ' + wagerValue)" class="material-icons">
      eject
    </span>
    <span *ngIf="!isShowdown && !isBet" (click)="notify('r ' + wagerValue)" class="material-icons">
      eject
    </span>

    <bet-slider #bslider *ngIf="!isShowdown" [(min)]="min" [(max)]="max"></bet-slider>


    <span *ngIf="!isShowdown" (click)="notify('a')" class="material-icons">
      casino
    </span>

    <span *ngIf="isShowdown" (click)="notify('s')" class="material-icons">
      pan_tool
    </span>
  </div>
    
  
</div>
    

