<content>
    <div *ngIf="game && !error">
        <h1>{{game.name}} Registered: 
            <span *ngIf="currentName">YES</span>
            <span *ngIf="!currentName">NO</span>
        </h1>
        <h2>State : {{game.state}}</h2>
        <h2>Max : {{game.maxPlayers}}</h2>
        <h2>Table : {{game.maxPlayersPerTable}}</h2>
        <h2>Thinking Time per Move (sec) : {{game.thinkingTimeInSec}}</h2>
        <h2 *ngIf="currentName">CurrentName : {{currentName}}</h2>
    </div>
    <player-settings (playerSettingsChanged)="onPlayerSettingsChanged($event)" *ngIf="!error"></player-settings>

    <div *ngIf="error">
        <h1>Your registration wasn't successful!</h1>
        <p>{{error.message}}</p>
    </div>
</content>