import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Currency } from '../shared/pot';
import { StackService } from './stack.service';

@Component({
  selector: '[stack]',
  templateUrl: './stack.component.html',
  styleUrls: ['./stack.component.css']
})


export class StackComponent implements OnInit {

  @Input() amount: number;
  @Input() bars: number;
  @Input() maxChips: number = 20;
  @Input() isHorizontal: boolean = false;
  @Input() cur: Currency;
  //https://angular.io/guide/component-interaction
  // https://www.themarketingtechnologist.co/building-nested-components-in-angular-2/
  @Output() onBarsUsed : EventEmitter<number> = new EventEmitter<number>();

  constructor(private stackService: StackService) { }

  get denominationsForSvg() {
    /*
    The config looks like this:
    [{count : 3, pic: "assets/chips/chip0025.png"}, {count : 3, pic: "assets/chips/chip0005.png"}, {count : 2, pic: "assets/chips/chip0001.png"}],
    [{count : 3, pic: "assets/chips/chip000025.png"}],
    [{count : 4, pic: "assets/chips/chip000005.png"}],
    [{count : 4, pic: "assets/chips/chip000001.png"},] */
    let returnable = this.stackService.convertAmountToStackConfig(this.amount, this.bars, this.maxChips, this.cur)
    let barsUsed = returnable.filter((e : Array<any>) => e.length > 0).length;
    this.onBarsUsed.emit(barsUsed)
    return returnable;
  }

  ngOnInit() {
  }

  get chipSize() : number {
    // for stars chips it's 249
    return 300
  }

}
