<div id="replay-container">
  <div (click)="notify('BACKWARD')" class="material-icons">
    fast_rewind
  </div>

    <div (click)="notify('PLAY')" *ngIf="!isReplaying" class="material-icons">
  play_circle_outline
  </div>

  <div (click)="notify('PAUSE')" *ngIf="isReplaying" class="material-icons">
    pause_circle_outline
  </div>

  <div (click)="notify('FORWARD')" class="material-icons">
    fast_forward
  </div>
  
</div>
    

<!--
      <span class="material-icons">
        delete
        </span>

        <span class="material-icons">
          done_outline
          </span>

          <span class="material-icons">
            eject
            </span>

            <span class="material-icons">
              pan_tool
              </span>
              <span class="material-icons">
                highlight_off
                </span> -->