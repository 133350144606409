<ng-template ngFor let-roll [ngForOf]="denominationsForSvg" let-i="index">
  <ng-template ngFor let-denom [ngForOf]="roll" let-j="index" >
    <ng-template ngFor let-item [ngForOf]="stackService.range(0 + stackService.sumUpAlreadyPlacedChips(roll,j), denom.count + stackService.sumUpAlreadyPlacedChips(roll,j))" >

        <svg:image *ngIf='!isHorizontal' class="size" attr.xlink:href="{{denom.pic}}" attr.y="{{i * chipSize + (-55 * item) }}%" attr.x="{{-5 * item}}%"/>
      <svg:image *ngIf='isHorizontal' class="size" attr.xlink:href="{{denom.pic}}" attr.x="{{i * chipSize}}%" attr.y="{{-55 * item}}%"/>
    </ng-template>
  </ng-template>
</ng-template>
  

  
