import { Player, PlayerState, UnitNumber } from './player';
import { DeepCopy } from "./clone";
import { isCaseOrDefaultClause } from 'typescript';
//  Location of domain classes:
//  https://stackoverflow.com/questions/37559272/recommended-location-for-domain-model-classes
export enum Currency {
  USD = "$",
  PLAY = "",
  EUR = "€",
  TOUR = "",
  GBP = "£",
  BB = "BB"

  
}

export function isMoney(cur : Currency) : boolean {
  const moneyCur : Array<Currency> = [Currency.EUR, Currency.USD, Currency.GBP]
  return moneyCur.includes(Currency[cur])
}

export class Pot {
  sidepots: Array<[number, Array<Player>]> = [];
  constructor(public amount: number = 0, public currency : Currency = Currency.PLAY) { }
  get currentPot(): [number, Array<Player>] {
    if (this.sidepots.length == 0) {
      return [this.amount, []];
    }
    else {
      return this.sidepots[this.sidepots.length - 1];
    }
  }
  get currentPotValue(): number {
    return this.currentPot[0];
  }
  addToCurrentPot(amount: number) {
    if (this.sidepots.length == 0) {
      this.amount += amount;
    }
    else {
      let currentSidePotValue = this.sidepots[this.sidepots.length - 1][0];
      this.sidepots[this.sidepots.length - 1][0] += amount;
    }
  }

  get potAsUnitNumber() : UnitNumber {
    return new UnitNumber(this.amount, this.currency)
  }

  clear() : void {
    if(this.sidepots.length > 0){
      this.sidepots = this.sidepots.slice(1)
    } else {
      this.amount = 0
    }
    
  }

  removeEmptyPots() {
    this.sidepots = this.sidepots.filter((sp) => sp[0] > 0)
  }

  addToNewtPot(amount: number, players: Array<Player> = []) {
    this.sidepots.push([amount, players]);
  }
}
export abstract class Straddle extends DeepCopy<Straddle> {
}
export class UTGStraddle extends Straddle {
  constructor(public amounts: Array<number>) {
    super();
  }
}
export class MississippiStraddle extends Straddle {
  constructor(indexPlayer: number, amount: number) {
    super();
  }
}
export class Blinds extends DeepCopy<Blinds> {
  constructor(public smallBlind: number, public bigBlind: number, public straddle: Straddle, public ante: Ante = new NoAnte()) {
    super();
  }
  getHighestAmount(): number {
    if (this.straddle instanceof UTGStraddle) {
      return this.straddle.amounts[this.straddle.amounts.length - 1];
    }
    else {
      return this.bigBlind;
    }
  }
}
export abstract class Ante extends DeepCopy<Ante> {
  constructor(public value: number) {
    super();
  }
  applyAnte(p: Player) {
    p.putInPlayUntil(this.value);
    p.state = PlayerState.PLACED_ANTE;
  }
}
export class ButtonAnte extends Ante {
}
export class BigBlindAnte extends Ante {
}
export class GeneralAnte extends Ante {
}
export class NoAnte extends Ante {
  constructor() {
    super(0);
  }
}
