export enum Rank {
  ACE = "A",
  DEUCE = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
  TEN = "T",
  JACK = "J",
  QUEEN = "Q",
  KING = "K",
  UNKNOWN = ""
}
export enum Suit {
  CLUB = "c",
  DIAMOND = "d",
  HEART = "h",
  SPADE = "s",
  UNKNOWN = "u"
}
export function cardFromString(parsable: string): Card {
  if (parsable.length != 2) {
    return [Rank.UNKNOWN, Suit.UNKNOWN];
  }
  else {
    let returnable: Card = [Rank.UNKNOWN, Suit.UNKNOWN];
    switch (parsable[0]) {
      case 'A':
        returnable[0] = Rank.ACE;
        break;
      case '2':
        returnable[0] = Rank.DEUCE;
        break;
      case '3':
        returnable[0] = Rank.THREE;
        break;
      case '4':
        returnable[0] = Rank.FOUR;
        break;
      case '5':
        returnable[0] = Rank.FIVE;
        break;
      case '6':
        returnable[0] = Rank.SIX;
        break;
      case '7':
        returnable[0] = Rank.SEVEN;
        break;
      case '8':
        returnable[0] = Rank.EIGHT;
        break;
      case '9':
        returnable[0] = Rank.NINE;
        break;
      case 'T':
        returnable[0] = Rank.TEN;
        break;
      case 'J':
        returnable[0] = Rank.JACK;
        break;
      case 'Q':
        returnable[0] = Rank.QUEEN;
        break;
      case 'K':
        returnable[0] = Rank.KING;
        break;
      default:
        return returnable;
    }
    switch (parsable[1]) {
      case 'c':
        returnable[1] = Suit.CLUB;
        break;
      case 'd':
        returnable[1] = Suit.DIAMOND;
        break;
      case 'h':
        returnable[1] = Suit.HEART;
        break;
      case 's':
        returnable[1] = Suit.SPADE;
        break;
      default: return returnable;
    }
    return returnable;
  }
}
export type Card = [Rank, Suit]
export type Cards = Array<Card>
export type WholeCards = [Card,Card]