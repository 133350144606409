import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { integerRange } from '../pkr-texas-holdem/shared/helper';
import { Rank, Suit } from "../pkr-table/shared/card";
import { Hand } from "../pkr-table/shared/hand";
import { handFor, generateSuperPosAll, HEADS_UP_PRE, HAND_FLOW_BLUFF_LSOP_BOSS, BLUFF_LSOP_BOSS, HAND_SIX_PLAYERS, gameAsString } from '../pkr-table/shared/fixtures';
import {Line } from '../pkr-texas-holdem/shared/lines';
import { AllIn, Call, Show, Check, Raise, Bet, Fold, SetCurrency, SetPlayers, SetStack, SetButton, SetBlinds, DealFlop, DealTurn, DealRiver, GameAction } from "../pkr-table/shared/action";
import { ApplicationState, Review, Lab, Slave } from '../pkr-texas-holdem/shared/model';
import { PkrTexasHoldemComponent } from '../pkr-texas-holdem/pkr-texas-holdem.component';

@Component({
  selector: 'holdem-demo',
  templateUrl: './holdem-demo.component.html',
  styleUrls: ['./holdem-demo.component.css']
})
export class HoldemDemoComponent implements OnInit {

  integerRange(arg0: number, arg1: number) {
    return integerRange(arg0, arg1)
  }


  @ViewChild('pokerTable') comp : PkrTexasHoldemComponent;

  hands : Array<Hand> = []
  hand : Hand
  state : ApplicationState

  constructor(private cd: ChangeDetectorRef) {
    this.hands = this.integerRange(2,10).map( (n : number) => handFor(n)) 
    let h : Array<Hand> = this.integerRange(2,10).map( (n : number) => generateSuperPosAll(n))
    this.hands = this.hands.concat( h);
    // deprecated 
    this.hand = <Hand>HEADS_UP_PRE
    let fixtureCards : Array<[[Rank, Suit], [Rank, Suit]]>= [  [[Rank.EIGHT, Suit.CLUB], [Rank.EIGHT, Suit.HEART]]   ,[[Rank.UNKNOWN, Suit.UNKNOWN],[Rank.UNKNOWN, Suit.UNKNOWN]],[[Rank.UNKNOWN, Suit.UNKNOWN],[Rank.UNKNOWN, Suit.UNKNOWN]],[[Rank.UNKNOWN, Suit.UNKNOWN],[Rank.UNKNOWN, Suit.UNKNOWN]], [[Rank.UNKNOWN, Suit.UNKNOWN],[Rank.UNKNOWN, Suit.UNKNOWN]], [[Rank.UNKNOWN, Suit.UNKNOWN],[Rank.UNKNOWN, Suit.UNKNOWN]] ]
    let board : Array<[Rank, Suit]> = [ [Rank.EIGHT,Suit.DIAMOND ],[Rank.DEUCE,Suit.DIAMOND],[Rank.KING,Suit.SPADE],[Rank.KING,Suit.HEART],[Rank.EIGHT,Suit.SPADE]]
    let l = new Line(fixtureCards,
      [new AllIn(),new AllIn()],
      [],
                                      [],
                                      [],
                                      [new Show([[Rank.DEUCE, Suit.DIAMOND],[Rank.SIX, Suit.CLUB]]), 
                                       new Show([[Rank.SEVEN, Suit.CLUB], [Rank.SEVEN, Suit.HEART]]), 
                                       new Show([[Rank.QUEEN, Suit.HEART],[Rank.THREE, Suit.SPADE]]), 
                                       new Show([[Rank.ACE, Suit.HEART],[Rank.DEUCE,   Suit.HEART]]),
                                       new Show([[Rank.ACE, Suit.DIAMOND],[Rank.ACE, Suit.CLUB]]),
                                       new Show([[Rank.EIGHT, Suit.CLUB],[Rank.EIGHT, Suit.HEART]])
                                      
                                      ],board)
    let lineSixPlayers = new Line(fixtureCards,
      [new Call(),new Raise(300),new Call(), new Call(), new Call()],
      [new Check(), new Check(), new Check(), new Check()], 
      [new Bet(700), new Fold(), new Fold(), new Fold()],
      [],
      [new Show([[Rank.SIX, Suit.SPADE],[Rank.SIX, Suit.CLUB]]), 
        new Show([[Rank.SEVEN, Suit.CLUB], [Rank.SEVEN, Suit.HEART]]), 
        new Show([[Rank.SEVEN, Suit.DIAMOND],[Rank.THREE, Suit.SPADE]]), 
      ],board)
                                      
      
      //this.state = new Review(HAND_FLOW_BLUFF_LSOP_BOSS, BLUFF_LSOP_BOSS)                                     
      //this.state = Review.FromStringRepresentation(gameAsString)
      //this.state = new Lab(Line.createEmpty(),this.hand)                                      
      this.state = new Slave(lineSixPlayers, HAND_SIX_PLAYERS)                                                                         
      

   }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    let state = this.state
    this.comp.initReviewMode(this.state.handState.players, this.state.handState, this.state.line)
    this.comp.oberserableTimerEndless()
    // https://medium.com/@nishu0505/using-viewchild-in-angular-de1854a51872
    this.cd.detectChanges();

  }

}
