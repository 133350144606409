<content class="wrapper">
    <header>
        <lsop-header></lsop-header>
    </header>
    <main>
        <router-outlet></router-outlet>
    </main>
    <footer>
        <lsop-footer></lsop-footer>
    </footer>
</content>