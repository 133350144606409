import { Component, OnInit } from '@angular/core';
import { RegisterService } from './register.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { empty, of } from "rxjs";
import { HttpResponse } from '@angular/common/http';
import { Api } from './shared/model';
import { ServerGameFactoryService, ServerGameSocket } from '../websocket/server-game-factory.service';
@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  error : Error
  
  game : Api.Game
  invitation : string
  currentName : string

  webSocket : ServerGameSocket
  // BIG TODO (mostly on server side - handle duplicate player names and show in GUI)

  constructor(private route: ActivatedRoute, 
              private register : RegisterService,
              private websocketFactory : ServerGameFactoryService) { }

  ngOnInit(): void {
    let game = this.route.snapshot.queryParamMap.get("game") 
    let invitation = this.route.snapshot.queryParamMap.get("invitation") 
    if(game && invitation){
      this.invitation = invitation
      // chain observables together
      // https://www.pluralsight.com/guides/switching-to-most-recent-observable-switchmap
      this.perfomVerification(parseInt(game), invitation);
    } else {
      this.error = new Error("No tournament or invitation infos are present!")
    }
  }

  onPlayerSettingsChanged(p : Api.Player){
    console.log(p)
    this.register.registerNewPlayer(p, this.invitation).subscribe(resp => {
      // 200
      this.perfomVerification(this.game.id, this.invitation);
    }, (error) => {
      console.error('error caught during name setting');
      console.error(error);
      this.error = new Error(error.message);
    });
  }

  private perfomVerification(gameId: number, invitation: string) {
    this.register.getTournamentInfo(gameId).pipe(switchMap((resp) => {
      console.log("Tournament");
      this.game = resp.body;
      console.log(this.game);
      // Game verified
      if (this.game.state == 'REGISTERING' || this.game.state == 'SEATING' || this.game.state == 'LATEREG') {
        return this.register.getInvitation(invitation);
      }
      else {
        // https://www.tektutorialshub.com/angular/angular-http-error-handling/
        if (this.game.state == 'SCHEDULED') {
          this.error = new Error("The registering of the tournament has not been started yet.");
        }
        else {
          this.error = new Error("The registration phase is over!");
        }
        // SHOW TURNAMENT RUNNING ETC. 
        return empty();
      }
    })).subscribe(resp => {
      // Invitation verified
      let body = resp.body;
      if (body) {
        this.currentName = resp.body.name;
        this.webSocket = this.websocketFactory.connectToGame(invitation)
        this.webSocket.getMessage().subscribe(m => console.log(m))
        //this.webSocket.sendMessage("test")
      }
      else {
        this.currentName = "You are not registered with a name!";
      }
    }, (error) => {
      console.error('error caught in component');
      console.error(error);
      this.error = new Error(error.message);
    });
  }
}
class Error {
    constructor(public message :string){}
};