<svg attr.height="{{height}}" attr.width="{{width}}">

  <!-- Table and feltline for betting -->
  <svg:ellipse id="table" cx="50%" cy="50%" attr.rx="{{radX}}" attr.ry="{{radY}}" class="felt table-outside"  stroke-width="2%" />
  <svg:ellipse id="table" cx="50%" cy="50%" attr.rx="{{radXGrayline}}" attr.ry="{{radYGrayline}}" class="felt-line" />
  
  <!-- Table logog-->
    <!-- LSoP-->
      <svg:image id="logo" xlink:href="assets/without_text.png" opacity="0.3" x="39%" y="33%" attr.height="{{heightLogo}}px" attr.width="{{widthLogo}}px"/>
    <!-- PoKu-->
    <!-- <svg:image id="logo" xlink:href="assets/poku_scaled.png" opacity="0.3" x="37.6%" y="33.3%" attr.height="{{heightLogo}}px" attr.width="{{widthLogo}}px"/> -->
    <!--
      ng for in angular
      https://coryrylan.com/blog/angular-ng-for-syntax
S
      pass complex object to subcomp
      https://stackoverflow.com/questions/38843532/how-to-pass-multiple-parameter-to-directives-in-angular-with-typescript
    -->



  <svg cards-row class="overflow"  [cardList]='handState.board' [cardsNonActive]='handState.board' [cardSize]='boardSize' x="32%" y="54.5%"   />
  <svg player-svg [displayInBB]="handState.layoutConfig.showInBB" [bbSize]="handState.blinds.getHighestAmount()" (isSetToBB)="toggleSetToBB()" class="overflow" [cardsSize]='playerCardSize' *ngFor="let item of layout; let i = index;" [showWagerDesc]="handState.layoutConfig.showWagerDesc" [item]='item' [player]="players[i]" attr.x="{{item.x}}%" attr.y="{{item.y}}%" attr.width="{{playerBoxWidth}}" attr.height="{{playerBoxHeight}}" [triangleSize]='triangleSize' />
  <!--<svg class="overflow" info-box  x="51%" y="37%" [content]="'Pot: $2.50'"  />-->
  <!--<svg class="overflow" stack [amount]="handState.pot" [bars]=7 attr.x="{{pot_layout.x}}%" attr.y="{{pot_layout.y}}%" height="1.1%" width="1.1%" [isHorizontal]='pot_layout.isHorizontal'/>  -->
  
  <!-- Mainpots --> 
  <svg pot [showPotDesc]="handState.layoutConfig.showPotDesc" [displayInBB]="handState.layoutConfig.showInBB" [bbSize]="handState.blinds.getHighestAmount()" [potSize]="handState.pot.potAsUnitNumber" [bars]=7 attr.x="{{mainpotPos[0]}}%" attr.y="{{mainpotPos[1]}}%" height="1.05%" width="1.05%" [isHorizontal]='mainpotPos[2]'/> 
7
  <!-- Sidepots -->
  <svg pot [showPotDesc]="handState.layoutConfig.showPotDesc" [displayInBB]="handState.layoutConfig.showInBB" [bbSize]="handState.blinds.getHighestAmount()" *ngFor="let sidepot of handState.pot.sidepots; let i = index;" [potSize]="sidepot[0]" [bars]="sidepotLayout[i].bars" attr.x="{{sidepotLayout[i].x}}%" attr.y="{{sidepotLayout[i].y}}%" height="1%" width="1.05%" [isHorizontal]='!sidepotLayout[i].isHorizontal'/> 
  
  <!--attr.x="{{pot_layout.x}}%" attr.y="{{pot_layout.y}}%" height="1.1%" width="1.1%"   -->
  <!-- Helper lines to allign with the middle-->
  <!-- <svg:line x1="0" y1="50%" x2="100%" y2="50%" style="stroke:rgb(255,0,0);stroke-width:2" />
  <svg:line x1="50%" y1="0" x2="50%" y2="100%" style="stroke:rgb(255,0,0);stroke-width:2" />  -->
</svg>

