import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { REPLAY_STATE } from './domain';

@Component({
  selector: 'replay-control',
  templateUrl: './replay-control.component.html',
  styleUrls: ['./replay-control.component.css']
})
export class ReplayControlComponent implements OnInit {

  @Input()
  isReplaying : boolean

  @Output() clickReplay = new EventEmitter<REPLAY_STATE>();
  constructor() { }

  ngOnInit() {
  }

  notify(state : string){
    let emittable : REPLAY_STATE = REPLAY_STATE[state]
    this.clickReplay.emit(emittable)
  }

}
