import { Injectable, NgModule } from '@angular/core';

import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { fromEvent } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ServerGameFactoryService {

  connectToGame(resource : string) : ServerGameSocket {
    console.log("request socket")
    return new ServerGameSocket(resource)
  }
}



export class ServerGameSocket  {
  subject : WebSocket

  constructor(resource : string, host : string = "localhost", port : string = "4200") {
    let domain = `${host}:${port}`
    let path = `/api/play/${resource}`
    //domain = "echo.websocket.org"
    //path = "/"
    this.subject = new WebSocket(`ws://${domain}${path}`);
    console.log(domain)
    this.subject.onopen = (event) => { this.subject.send(JSON.stringify({'jwt': 'blaat'})) }

  }

  sendMessage(msg: string) {
    console.log("try to send: " + msg)
    this.subject.send(msg);
  }

  getMessage() : Observable<Event> {
    return fromEvent(this.subject, 'message');
  }
}
