import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'bet-slider',
  templateUrl: './bet-slider.component.html',
  styleUrls: ['./bet-slider.component.css']
})
export class BetSliderComponent implements OnInit {

  val : number = 0

  @Input()
  min : number = 0


  @Input()
  max : number = 0

  @Input() width : number = 140
  

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["min"]){
      this.min = <number> <unknown>changes["min"]["currentValue"]
      this.val = this.min
    }
  }

  constructor() { }

  ngOnInit() {
    
  }



}
