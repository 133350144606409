import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Player } from './shared/player';
import { Hand } from "./shared/hand";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'pkr-table',
  templateUrl: './pkr-table.component.html',
  styleUrls: ['./pkr-table.component.css']
})
export class PkrTableComponent implements OnInit {

  playerLayout = {
    "2": [{"x" : 42.6, "y" : 78}, {"x" : 42.6, "y" : 8} ],
    "3": [{"x" : 42.6, "y" : 78}, {"x" : 10, "y" : 15},{"x" : 75, "y" : 15} ],
    "4": [{"x" : 42.6, "y" : 78}, {"x" : 0, "y" : 41.5}, {"x" : 42.6, "y" : 8},{"x" : 88, "y" : 41.5}  ],
    "5": [{"x" : 42.6, "y" : 78}, {"x" : 10, "y" : 67.5},{"x" : 10, "y" : 15}, {"x" : 80, "y" : 15}, {"x" : 80, "y" : 67.5},],
    "6": [{"x" : 42.6, "y" : 78}, {"x" : 10, "y" : 67.5},{"x" : 10, "y" : 15}, {"x" : 42.6, "y" : 8}, {"x" : 80, "y" : 15}, {"x" : 80, "y" : 67.5}],
    "7": [{"x" : 42.6, "y" : 78}, {"x" : 12.5, "y" :67.5}, {"x" : 0, "y" : 24}, {"x" : 25, "y" : 8}, {"x" : 60, "y" : 8}, {"x" : 85, "y" : 24}, {"x" :77.5, "y" : 67.5}],
    "8": [{"x" : 25, "y" : 72}, {"x" : 0, "y" : 64}, {"x" : 0, "y" : 20}, {"x" : 25, "y" : 8}, {"x" : 60, "y" : 8}, {"x" : 85, "y" : 20}, {"x" : 85, "y" : 64}, {"x" : 60, "y" : 72}],
    "9": [{"x" : 42.6, "y" : 78},{"x" : 20.2, "y" : 72}, {"x" : 0.2, "y" : 60}, {"x" : 0, "y" : 20}, {"x" : 25, "y" : 8}, {"x" : 60, "y" : 8}, {"x" : 85, "y" : 20}, {"x" : 86, "y" : 60}, {"x" : 67, "y" : 72}],
    "10": [{"x" : 42.6, "y" : 78},{"x" : 20.2, "y" : 72}, {"x" : 0.2, "y" : 65}, {"x" : 0.2, "y" : 20}, {"x" : 20.2, "y" : 8}, {"x" : 42.6, "y" : 5}, {"x" : 65, "y" : 8}, {"x" : 85, "y" : 20}, {"x" : 85, "y" : 65}, {"x" : 65, "y" : 72}],
  }

  // max 8 sidepoints + 1 mainpot (#pots = #player - 1)
  sidepotLayout = [
    {"x" : 27, "y" : 45, isHorizontal: true, bars: 1},
    {"x" : 75, "y" : 45, isHorizontal: true , bars: 1},
    {"x" : 36, "y" : 37, isHorizontal: true, bars: 1},
    {"x" : 66, "y" : 37, isHorizontal: true, bars: 1},
    {"x" : 27, "y" : 35, isHorizontal: true, bars: 1},
    {"x" : 73, "y" : 35, isHorizontal: true, bars: 1},
    {"x" : 27, "y" : 55, isHorizontal: true, bars: 1 },
    {"x" : 73, "y" : 55, isHorizontal: true, bars: 1},
    
  ]

  title = 'Pkr RE Ply';

  @Input() width : number;

  @Input() handState : Hand;

  get players() : Array<Player> {
    return this.handState.players
  }
  
  layout : Array<any>


  constructor(private sanitizer: DomSanitizer){

  }

  ngOnInit(): void {
    
    // to string in typescript
  // https://stackoverflow.com/questions/32554624/casting-a-number-to-a-string-in-typescript
    this.layout = this.playerLayout[String(this.players.length)];
  }

  toggleSetToBB() : void {
    this.handState.layoutConfig.showInBB = !this.handState.layoutConfig.showInBB
  }

  get height(): number {
    return this.width / 4 * 3;
  }
  get widthLogo(): number{
    return this.width / 4;
  }
  get heightLogo(): number {
    return this.widthLogo;
  }

  get radX(): number {
    return this.height / 1.7
  };
  get radY(): number {
    return this.radX / 2;
  }

  get radXGrayline(): number {
    return this.height / 1.95
  };
  get radYGrayline(): number {
    return this.radX / 2.4;
  }

  get playerBoxWidth(): number {
    return this.width / 4.8;
  }
  get playerBoxHeight(): number {
    return this.height / 4.8;
  }
  get boardSize(): number {
    return this.width / 200;
  }

  get playerCardSize() : number {
    return this.width / 300;
  }

  get triangleSize(): number {
    return this.width / 20;
  }

  // https://medium.com/@ingobrk/using-css-variables-in-angular-282a9edf1a20
  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`
    --fontsize-playername: ${this.playerFontSize}px;
    --fontsize-stack: ${this.stackFontSize}px;
    --fontsize-action: ${this.actionFontSize}px;
    --fontsize-wager: ${this.wagerFontSize}px;
    --fontsize-pot: ${this.potFontSize}px;
    --fontsize-sidepot: ${this.sidepotFontSize}px;
    --strokesize: ${this.strokesize}px;
    `)
                         
  }

  get strokesize() : number {
    return this.width / 1200;
  }

  get potFontSize() : number { 
    return this.width / 35;  
  }

  get sidepotFontSize() : number { 
    return this.width / 45;  
  }

  get wagerFontSize() : number { 
    return this.width / 42;  
  }

  get playerFontSize() : number { 
    return this.width / 50;  
  }

  get stackFontSize() : number {
    return this.width / 40; 
  }

  get actionFontSize() : number {
    return this.width / 30; 
  }

  get mainpotPos() : [number, number, boolean] {
    return [42, 40, true]
  }
}
