import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LsopHeaderComponent } from './lsop-header/lsop-header.component';
import { LsopFooterComponent } from './lsop-footer/lsop-footer.component';

// Prime ng
import { FormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// use prime ng
import {MenubarModule} from 'primeng/menubar';

@NgModule({
  declarations: [LsopHeaderComponent, LsopFooterComponent],
  imports: [
    FormsModule,
    BrowserAnimationsModule,
    CommonModule,

    MenubarModule
  ],
  exports: [
    LsopHeaderComponent,
    LsopFooterComponent
  ]
})
export class LayoutModule { }
