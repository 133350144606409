import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lsop-footer',
  templateUrl: './lsop-footer.component.html',
  styleUrls: ['./lsop-footer.component.css']
})
export class LsopFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
