import { Component, OnInit, Input } from '@angular/core';
import { stringify } from 'querystring';

@Component({
  selector: '[all-in-triangle]',
  templateUrl: './all-in-triangle.component.html',
  styleUrls: ['./all-in-triangle.component.css']
})
export class AllInTriangleComponent implements OnInit {
  @Input()
  base_length : number = 60

  
  border_length : number
  constructor() { }

  ngOnInit() {
    this.border_length = this.base_length / 10
  }

  private coordinates_base(offset : number = 0): Array<[number,number]> {
    let length = this.base_length - offset
    let height = (this.base_length * Math.sqrt(3)) / 2
    let mid = this.base_length / 2
    return [[mid, offset], [offset,height - offset / 2], [length,height - offset / 2]]
  }

  coordinates(): string {
    let base_coord = this.coordinates_base() 
    
    return base_coord.map(_ => _.toString()).join(",")
  }

  coordinatesInner(): string {
    
    let base_coord = this.coordinates_base(this.border_length) 
    
    return base_coord.map(_ => _.toString()).join(",")
  }

  coordXCaption(): string {
    let x = this.base_length / 2
    
    return `${x}px`
  }
  
  coordYCaption(): string {
    let height : number = this.coordinates_base()[1][1]
    let y = height / 1.15;
    return `${y}px`
  }

  fontSize() : string {
    return `${this.base_length / 6}px`
  }

  strokeWidth() : string {
    return `${this.base_length / 25}px`
  }
}
