import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Api } from '../shared/model';

@Component({
  selector: 'player-settings',
  templateUrl: './player-settings.component.html',
  styleUrls: ['./player-settings.component.css']
})
export class PlayerSettingsComponent implements OnInit {

  @Output() playerSettingsChanged = new EventEmitter<Api.Player>();
  playerName : string

  constructor() { }

  ngOnInit(): void {
  }

  notifyPlayerSettings() {
    this.playerSettingsChanged.emit(new Api.Player(this.playerName));
    console.log(this.playerName)
  }

}
