import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PkrTableComponent } from './pkr-table.component';
import { PlayerSvgComponent } from './player-svg/player-svg.component';
import { StackComponent } from './stack/stack.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { CardsComponent } from './cards-row/cards/cards.component';
import { CardsRowComponent } from './cards-row/cards-row.component';
import { ButtonComponent } from './player-svg/button/button.component';
import { AllInTriangleComponent } from './player-svg/all-in-triangle/all-in-triangle.component';
import { WagerComponent } from './wager/wager.component';
import { AmountFormatPipe } from './amount-format.pipe';
import { PotComponent } from './pot/pot.component';
import { MoneyPipe } from './money.pipe';
import { CurrencyPipe } from './currency.pipe';

@NgModule({
  declarations: [PkrTableComponent, PlayerSvgComponent,StackComponent, InfoBoxComponent, CardsComponent, CardsRowComponent, ButtonComponent, AllInTriangleComponent, WagerComponent, AmountFormatPipe, PotComponent, MoneyPipe, CurrencyPipe],
  imports: [
    CommonModule
  ],
  exports: [
    PkrTableComponent, CardsRowComponent
  ]
})
export class PkrTableModule { }
