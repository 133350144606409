import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Api } from './shared/model'

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  getTournamentInfo(id : number) : Observable<HttpResponse<Api.Game>> {
    return this.http.get<Api.Game>(`/api/game/${id}`, { observe: 'response' })
  }

  getInvitation(id : String) : Observable<HttpResponse<any>> {
    return this.http.get(`/api/invitation/lookup/${id}/player`, { observe: 'response' })
  }

  registerNewPlayer(p : Api.Player, invitation : String) : Observable<HttpResponse<any>> {
    // https://www.playframework.com/documentation/2.8.x/ScalaCsrf
     return this.http.post(`/api/invitation/register/${invitation}`, p, { observe: 'response', headers : {"Csrf-Token" : "nocheck"} })
  }
}
