import { Injectable } from '@angular/core';
import { Currency, isMoney } from '../shared/pot';

@Injectable({
  providedIn: 'root'
})
export class StackService {
  availableDenomitionations : Array<number>
  pathToChipPics : string

  constructor() {
    this.availableDenomitionations = [500000000,100000000, 50000000, 10000000, 2500000, 500000, 100000, 50000, 10000, 2500, 500, 100, 25, 5, 1]
    this.pathToChipPics = "assets/chips_own/"   
   }

  // https://wsvincent.com/javascript-array-range-function/
  range(start, edge, step) {
    // If only 1 number passed make it the edge and 0 the start
    if (arguments.length === 1) {
      edge = start;
      start = 0;
    }
  
    // Validate edge/start
    edge = edge || 0;
    step = step || 1;
  
    // Create array of numbers, stopping before the edge
    let arr = [];
    for (arr; (edge - start) * step > 0; start += step) {
      arr.push(start);
    }
    return arr;
  }

  sumUpAlreadyPlacedChips(roll, j) {
    return roll.slice(0,j).map((e) => e.count).reduce((a,b) => a + b, 0)
    
  }

  convertAmountToStackConfig(amount, countRolls = 5, maxChips = 20, cur : Currency){
    let returnable = [];
    for (let i = 0; i < countRolls; i++){
      returnable.push([])
    }
    let setIndex = 0; 
    for(let i = 0; i < this.availableDenomitionations.length; i++){
      let curDenom = this.availableDenomitionations[i];
      let countDenomChip = Math.floor(amount / curDenom);
      if(countDenomChip > 0){
        let curChipPath = this.createCurChipPathOfDenom(curDenom, cur)
        let denomConfig = {count : countDenomChip, pic : curChipPath}
        let curBar = returnable[setIndex % countRolls]
        curBar.push(denomConfig)
        amount -= countDenomChip * curDenom
        setIndex++
        if(this.sumUpAlreadyPlacedChips(curBar, this.availableDenomitionations.length) > maxChips){
          break
        }
      }
    }
    for (let i = 0; i < countRolls; i++){
      returnable[i].reverse();
    }
    return returnable;
  }

  createCurChipPathOfDenom(denom, cur : Currency){  
    let adjustedDenom = denom 
    if(isMoney(cur)) {
      adjustedDenom = denom / 100
    } 
    switch(adjustedDenom){
      case 1:
      case 5:
      case 25:
        return `${this.pathToChipPics}/chip${this.pad(adjustedDenom, 6)}.png`
      case 100:
      case 500:
      case 2500:
      case 10000:
      case 50000:
        return `${this.pathToChipPics}/chip${this.pad(adjustedDenom, 4)}.png`
      default:
        return `${this.pathToChipPics}/chip${adjustedDenom}.png` 
    }
  }

  pad(num, size) {
    var s = String(num);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  }
}
