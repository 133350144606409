import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Rank, Suit } from "../../shared/card";
import { CardsService } from './cards.service';

@Component({ 
  selector: '[cards]',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {
    constructor(private cardsService: CardsService) { }

    @Input() rank: Rank;
    @Input() suit: Suit;

    get cardTuple() : [Rank, Suit] {
      return  [this.rank, this.suit]
    }

    @Input() cardSize : number = 3;

    @Input() active : boolean = true

    @Input() isClicked : boolean = false
    @Output() onCardClicked = new EventEmitter<[Rank, Suit]>();

    cardUnicode : string;
    ngOnInit() {
      if(typeof(this.rank) === 'string' && this.rank.length > 1){
        this.rank = Rank[this.rank]
      }
      if(typeof(this.suit) === 'string' && this.rank.length > 1){
        this.suit = Suit[this.suit]
      }
    }

    cardClicked() {   
      this.onCardClicked.emit(this.cardTuple);
      this.isClicked = !this.isClicked;
    }

    get stroke() : string {
      return (this.cardSize * 2) + 'px' ;
    }

    get suitCssClass(): string {
      return this.suit;
    }

    getUnicodeChar() : string {
      return this.cardsService.cardToUnicodeChar(this.rank, this.suit);
    }
  

}
